import { mapActions } from "vuex";
import moment from "moment";
import { mapGetters } from "vuex";

const common = {
    watch: {
        $route(to) {
            document.title = to.meta.title + " - QPortal" || "QPortal";
        },
    },
    data() {
        return {
            cardStyle: {
                border: "2px solid #A5A5A5FF",
            },
        };
    },
    computed: {
        ...mapGetters({
            checkWritePermission: "checkWritePermission",
            checkDeletePermission: "checkDeletePermission",
            checkExportPermission: "checkExportPermission",
            checkReadPermission: "checkReadPermission",
            checkBackfillPermission: "checkBackfillPermission",
            currencyCode: "currencyCode",
        }),
    },

    methods: {
        ...mapActions(["showSnack", "showOverlayLoader"]),
        showSuccess(message) {
            this.showSnack({
                text: message,
                type: "success",
                timeout: 10000,
            });
        },
        showError(message) {
            this.showSnack({
                text: message,
                type: "error",
                timeout: 10000,
            });
        },
        showInfo(message, timeout) {
            this.showSnack({
                text: message,
                type: "info",
                timeout: timeout == null ? 10000 : timeout,
            });
        },
        showLoader(message) {
            this.showOverlayLoader({
                text: message,
                show: true,
            });
        },
        hideLoader() {
            this.showOverlayLoader({
                text: "",
                show: false,
            });
        },
        downloadFile(response, name, type = "") {
            const blob = new Blob([response.data], {
                type: response.data.type,
            });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            response.headers["content-disposition"];
            let fileName = "";
            if (type == "pdf") {
                fileName = `${name}_${moment().format("YYYYMMDDHHmmss")}.pdf`;
            } else if (type == "zip") {
                fileName = `${name}_${moment().format("YYYYMMDDHHmmss")}.zip`;
            } else {
                fileName = `${name}_${moment().format("YYYYMMDDHHmmss")}.xlsx`;
            }
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
        },

        printFile(response) {
            let fileType = response.data.type ?
                response.data.type :
                "application/pdf";
            const blob = new Blob([response.data], {
                type: fileType,
            });
            var blobURL = URL.createObjectURL(blob);
            const iframe = document.createElement("iframe");
            document.body.appendChild(iframe);
            iframe.style.display = "none";
            iframe.src = blobURL;
            iframe.onload = function() {
                setTimeout(function() {
                    iframe.focus();
                    iframe.contentWindow.print();
                }, 1);
            };
        },

        downloadReport(response, name, type = "") {
            const blob = new Blob([response.data], {
                type: response.data.type,
            });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            response.headers["content-disposition"];
            let fileName = "";
            if (type == "pdf") {
                // fileName = `${name}_${moment().format("YYYYMMDDHHmmss")}.pdf`;
                fileName = `${name}.pdf`;
            } else {
                // fileName = `${name}_${moment().format("YYYYMMDDHHmmss")}.xlsx`;
                fileName = `${name}.xlsx`;
            }
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
        },
        openFile(path) {
            window.open(this.s3BucketURL + path, "_blank");
        },
        errorChecker(error) {
            var response = error;
            console.log(error);
            this.hideLoader();
            if (error.response) {
                response = error.response;
            }
            let that = this;
            if (
                response &&
                response.request &&
                response.request.responseType == "blob"
            ) {
                let fr = new FileReader();
                fr.onload = function(e) {
                    response = { data: JSON.parse(e.target.result) };
                    that.showMessageFromError(response);
                };
                fr.readAsText(response.data);
            } else {
                this.showMessageFromError(response);
            }
        },
        showMessageFromError(response) {
            setTimeout(() => {
                let message =
                    typeof response.data != "undefined" &&
                    typeof response.data.message != "undefined" ?
                    response.data.message :
                    "Unknown error occurred.";

                this.showError(message);
                this.hideLoader();
            });
        },
        findFirstPage() {
            if (
                this.$store.getters.checkReadPermission(
                    this.$modules.general.dashboard.slug
                )
            ) {
                this.$router.push("/dashboard", () => {});
            } else if (
                this.$store.getters.checkReadPermission(
                    this.$modules.facility.schedule.slug
                )
            ) {
                this.$router.push("/schedule", () => {});
            } else if (
                this.$store.getters.checkReadPermission(
                    this.$modules.events.schedule.slug
                )
            ) {
                this.$router.push("/event-schedule", () => {});
            } else if (
                this.$store.getters.checkReadPermission(
                    this.$modules.workshops.schedule.slug
                )
            ) {
                this.$router.push("/schedule", () => {});
            } else if (
                this.$store.getters.checkReadPermission(
                    this.$modules.facility.management.slug
                )
            ) {
                this.$router.push("/facilities", () => {});
            } else if (
                this.$store.getters.checkReadPermission(
                    this.$modules.events.management.slug
                )
            ) {
                this.$router.push("/events", () => {});
            } else if (
                this.$store.getters.checkReadPermission(
                    this.$modules.workshops.management.slug
                )
            ) {
                this.$router.push("/workshops", () => {});
            } else if (
                this.$store.getters.checkReadPermission(
                    this.$modules.clients.customers.slug
                )
            ) {
                this.$router.push("/customers", () => {});
            } else if (
                this.$store.getters.checkReadPermission(
                    this.$modules.trainers.management.slug
                )
            ) {
                this.$router.push("/trainers", () => {});
            } else if (
                this.$store.getters.checkReadPermission(this.$modules.sales.graph.slug)
            ) {
                this.$router.push("/sales", () => {});
            } else if (
                this.$store.getters.checkReadPermission(
                    this.$modules.sales.credits.slug
                )
            ) {
                this.$router.push("/credits", () => {});
            } else if (
                this.$store.getters.checkReadPermission(
                    this.$modules.sales.refunds.slug
                )
            ) {
                this.$router.push("/refunds", () => {});
            } else if (
                this.$store.getters.checkReadPermission(this.$modules.sales.void.slug)
            ) {
                this.$router.push("/cancellations", () => {});
            } else if (
                this.$store.getters.checkReadPermission(this.$modules.memberships.schedule.slug)
            ) {
                this.$router.push("/memberships/attendance-log", () => {});
            } else if (
                this.$store.getters.checkReadPermission(
                    this.$modules.settings.profile.slug
                )
            ) {
                this.$router.push("/settings/profile", () => {});
            } else if (
                this.$store.getters.checkReadPermission(
                    this.$modules.kiosk.facility.slug
                )
            ) {
                this.$store.dispatch("switchToKiosk", true).then(() => {
                    let venue = this.$store.getters.userInfo.venues[0]?.name;
                    if(venue && venue.toLocaleLowerCase() !='abrahamic family house'){
                        window.location.href = `${process.env.VUE_APP_KIOSK_DOMAIN}?token=${this.$store.getters.token}&login_url=${window.location.origin}`;
                    }else{
                        this.$router.push("/kiosk", () => {});
                        this.$forceUpdate();
                    }
                });
            } else {
                this.$router.push("/forbidden", () => {});
            }
        },
        getTaxVariation(type, taxPercentage, amount) {
            if (amount) {
                if (type == "post") {
                    let taxAmount = parseFloat(
                        (parseFloat(amount) / (100 + parseFloat(taxPercentage))) *
                        parseFloat(taxPercentage)
                    );
                    return {
                        tax: taxAmount,
                        price: parseFloat(amount) - taxAmount,
                        total_price: parseFloat(amount),
                    };
                } else {
                    let taxAmount = parseFloat(
                        parseFloat(amount) * (parseFloat(taxPercentage) / 100)
                    );
                    return {
                        tax: taxAmount,
                        price: parseFloat(amount),
                        total_price: parseFloat(amount) + taxAmount,
                    };
                }
            }
        },
        logIt(data) {
            console.log(data);
        },
    },
};

export default common;