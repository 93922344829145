const Modules = {
  facility: {
    schedule: {
      name: "Ground Schedule",
      slug: process.env.VUE_APP_FACILITY_SCHEDULE,
    },
    control: {
      name: "Schedule Control",
      slug: process.env.VUE_APP_FACILITY_SCHEDULE_CONTROL,
    },
    management: {
      name: "Ground Management",
      slug: process.env.VUE_APP_FACILITY,
    },
    cashbackProducts: {
      name: "Cash back Products",
      slug: process.env.VUE_APP_FACILITY_CASHBACK_PRODUCTS,
    },
    seasonalPricing: {
      name: "Seasonal Pricing",
      slug: process.env.VUE_APP_FACILITY_SEASONAL_PRICING,
    },
    overnightBooking: {
      name: "Over-night Booking",
      slug: process.env.VUE_APP_FACILITY_OVER_NIGHT_BOOKING,
    },
    podProducts:{
        name: "POD Products",
        slug: process.env.VUE_APP_FACILITY_POD_PRODUCTS,
    },
    timeRates:{
        name: "Time Rates",
        slug: process.env.VUE_APP_FACILITY_TIME_RATES,
    },
    addonProducts:{
        name: "Add-on Products",
        slug: process.env.VUE_APP_FACILITY_ADDON_PRODUCTS,
    },
    configuration:{
        name:"Facility Configuration",
        slug:process.env.VUE_APP_FACILITY_CONFIGURATION,
    }
  },
  events: {
    schedule: {
      name: "Event Schedule",
      slug: process.env.VUE_APP_EVENT_SCHEDULE,
    },
    management: {
      name: "Event Management",
      slug: process.env.VUE_APP_EVENT_MANAGEMENT,
    },
    schedules: {
      name: "Event Schedules",
      slug: process.env.VUE_APP_EVENT_SCHEDULES,
    },
    sales: {
      name: "Event Sales",
      slug: process.env.VUE_APP_EVENT_SALES,
    },
  },
  workshops: {
    schedule: {
      name: "Workshop Schedule",
      slug: process.env.VUE_APP_WORKSHOP_SCHEDULE,
    },
    management: {
      name: "Workshop Management",
      slug: process.env.VUE_APP_WORKSHOP,
    },
    sales: {
      name: "Workshop Sales",
      slug: process.env.VUE_APP_WORKSHOP_SALES,
    },
    programBased:{
      name:"Workshop Program Based",
      slug:process.env.VUE_APP_WORKSHOP_PROGRAM_BASED,
    }
  },
  space: {
    management: {
      name: "Space Management",
      slug: process.env.VUE_APP_SPACE,
    },
  },
  trainers: {
    dashboard: {
      name: "Trainer Management",
      slug: process.env.VUE_APP_PERSONAL_TRAINING,
    },
    management: {
      name: "Trainer Customers",
      slug: process.env.VUE_APP_PERSONAL_CUSTOMERS,
    },
    availability: {
      name: "Trainer Availability",
      slug: process.env.VUE_APP_PERSONAL_CUSTOMERS_AVAILABILITY,
    },
    commission: {
      name: "Trainer Commission",
      slug: process.env.VUE_APP_PERSONAL_TRAINER_COMMISSION,
    },
    sales: {
      name: "Trainer Sales",
      slug: process.env.VUE_APP_PERSONAL_SALES,
    },
    revenue: {
      name: "Trainer Revenue",
      slug: process.env.VUE_APP_PERSONAL_TRAINER_EARNED_REVENUE,
    },
    resetCommission: {
      name: "Trainer Reset Commission",
      slug: process.env.VUE_APP_PERSONAL_TRAINER_RESET_COMMISSION,
    },
    packageValidity: {
      name: "Trainer Package Validity",
      slug: process.env.VUE_APP_PERSONAL_TRAINER_PACKAGE_VALIDITY,
    },
  },
  clients: {
    customers: {
      name: "Customer Details",
      slug: process.env.VUE_APP_CUSTOMERS,
    },
    graph: {
      name: "Customer Graph",
      slug: process.env.VUE_APP_CUSTOMER_GRAPH,
    },
    advanced_settings: {
      name: "Customer Advanced Settings",
      slug: process.env.VUE_APP_CUSTOMER_ADVANCED,
    },
    wallet_management: {
      name: "Customer Wallet Management",
      slug: process.env.VUE_APP_CUSTOMER_WALLET_MANAGEMENT,
    },
    customer_merge: {
      name: "Customer Merge Management",
      slug: process.env.VUE_APP_CUSTOMER_MERGE_MANAGEMENT,
    },
    restrict_app_login: {
      name: "Restrict App Login from Multiple Devices",
      slug: process.env.VUE_APP_CUSTOMERS_QLR,
    },
  },
  memberships: {
    dashboard: {
      name: "Memberships",
      slug: process.env.VUE_APP_MEMBERSHIPS,
    },
    management: {
      name: "Memberships Management",
      slug: process.env.VUE_APP_MEMBER_MANAGEMENT,
    },
    schedule: {
      name: "Memberships Attendance Log",
      slug: process.env.VUE_APP_MEMBER_SCHEDULE,
    },
    attendance: {
      name: "Memberships Attendance",
      slug: process.env.VUE_APP_MEMBER_ATTENDANCE,
    },
    analytics: {
      name: "Memberships Analytics",
      slug: process.env.VUE_APP_MEMBER_ANALYTICS,
    },
    unrestricted_view: {
      name: "View Profile",
      slug: process.env.VUE_APP_MEMBER_UNRESTRICTED_VIEW,
    },
    credential_view: {
      name: "Credential View",
      slug: process.env.VUE_APP_MEMBER_CREDENTIAL_VIEW,
    },
    freeze: {
      name: "Membership Freeze",
      slug: process.env.VUE_APP_MEMBERSHIP_FREEZE,
    },
    upgrade: {
      name: "Membership Upgrade",
      slug: process.env.VUE_APP_MEMBERSHIP_UPGRADE,
    },
    autoDebit:{
      name:"Membership installment auto-debit",
      slug:process.env.VUE_APP_MEMBERSHIP_AUTO_DEBIT,
    },
    sales:{
      name:"Membership Sales",
      slug:process.env.VUE_APP_MEMBERSHIP_SALES,
    },
    members:{
      name:"Membership Members",
      slug:process.env.VUE_APP_MEMBERSHIP_MEMBERS,
    }
  },
  b2cconfig: {
    general: {
      name: "B2C General Config",
      slug: process.env.VUE_APP_B2C_CONFIG,
    },
    toggleCheckout: {
      name: "Enable/Disable Checkout",
      slug: process.env.VUE_APP_TOGGLE_CHECKOUT,
    },
    home: {
      name: "B2C Home Config",
      slug: process.env.VUE_APP_B2C_CONFIG,
    },
    sports: {
      name: "B2C Sports Config",
      slug: process.env.VUE_APP_B2C_CONFIG,
    },
    fBParticipantDetails: {
      name: "Facility Booking Participant Details",
      slug: process.env.VUE_APP_FB_PARTICIPANT_DETAILS,
    },
    timeCapacityFilter: {
      name: "Facility Time/Capacity Filter",
      slug: process.env.VUE_APP_FACILITY_TIME_CAPACITY_FILTER,
    },
    blogs: {
      name: "Blogs",
      slug: process.env.VUE_APP_B2C_BLOGS,
    },
    customDuration: {
      name: "Custom Duration",
      slug: process.env.VUE_APP_B2C_CUSTOM_DURATION,
    },
    cartReservation: {
      name: "Cart Reservation",
      slug: process.env.VUE_APP_CART_RESERVATION
    }
  },
  sales: {
    graph: {
      name: "Sales Graph",
      slug: process.env.VUE_APP_SALES_GRAPH,
    },
    logs: {
      name: "Sales Logs",
      slug: process.env.VUE_APP_SALES_LOGS,
    },
    credits: {
      name: "Credit Logs",
      slug: process.env.VUE_APP_SALES_CREDITS,
    },
    refunds: {
      name: "Refund Logs",
      slug: process.env.VUE_APP_SALES_REFUNDS,
    },
    void: {
      name: "Void Transaction",
      slug: process.env.VUE_APP_SALES_VOID,
    },
    refund: {
      name: "Refund Transaction",
      slug: process.env.VUE_APP_SALES_REFUND,
    },
    paymentLink: {
      name: "Email Payment Link",
      slug: process.env.VUE_APP_SALES_PAYMENT_LINK,
    },
    wallet: {
      name: "Wallet",
      slug: process.env.VUE_APP_SALES_WALLET,
    },
  },
  promotions: {
    management: {
      name: "Promotion Dashboard",
      slug: process.env.VUE_APP_PROMOTIONS,
    },
    singleUserPromo: {
      name: "Single Use Promotions",
      slug: process.env.VUE_APP_SINGLE_USE_PROMOTIONS,
    },
  },
  vouchers: {
    management: {
      name: "Gift Voucher",
      slug: process.env.VUE_APP_GIFT_VOUCHERS,
    },
    issues: {
      name: "Voucher Issues",
      slug: process.env.VUE_APP_VOUCHER_ISSUES,
    },
  },
  marketing: {
    dashboard: {
      name: "Marketing Dashboard",
      slug: process.env.VUE_APP_USER_PROFILE,
    },
    facebook: {
      name: "Facebook Marketing",
      slug: process.env.VUE_APP_MARKETING_FACEBOOK,
    },
    email_template_configuration:{
      name:"Template Configuration",
      slug: process.env.VUE_APP_USER_PROFILE,
    },
    instagram: {
      name: "Instagram Marketing",
      slug: process.env.VUE_APP_MARKETING_INSTAGRAM,
    },
    twitter: {
      name: "Twitter Marketing",
      slug: process.env.VUE_APP_MARKETING_TWITTER,
    },
    youtube: {
      name: "Youtube Marketing",
      slug: process.env.VUE_APP_MARKETING_YOUTUBE,
    },
    snapchat: {
      name: "Snapchat Marketing",
      slug: process.env.VUE_APP_MARKETING_SNAPCHAT,
    },
    krews: {
      name: "Krews Marketing",
      slug: process.env.VUE_APP_MARKETING_KREWS,
    },
    sms: {
      name: "SMS Marketing",
      slug: process.env.VUE_APP_MARKETING_SMS,
    },
    email: {
      name: "SMS Marketing",
      slug: process.env.VUE_APP_MARKETING_EMAIL,
    },
    newsletter: {
      name: "Newsletter subscription",
      slug: process.env.VUE_APP_NEWSLETTER,
    },
    order_confirmation: {
      name: "Order confirmation",
      slug: process.env.VUE_APP_ORDER_CONFIRMATION,
    },
    order_change: {
      name: "Order change",
      slug: process.env.VUE_APP_ORDER_CHANGE,
    },
    order_cancel: {
      name: "Order cancel",
      slug: process.env.VUE_APP_ORDER_CANCEL,
    },
    new_member_mail: {
      name: "New Member",
      slug: process.env.VUE_APP_NEW_MEMBER,
    },
    renew_member_mail: {
      name: "Renew Member",
      slug: process.env.VUE_APP_RENEW_MEMBER,
    },
    welcome_mail: {
      name: "Welcome Email",
      slug: process.env.VUE_APP_WELCOME_MAIL,
    },
    survey_mail: {
      name: "Survey Mail",
      slug: process.env.VUE_APP_SURVEY_MAIL,
    },
    e_invoice_mail: {
      name: "E Invoice Mail",
      slug: process.env.VUE_APP_E_INVOICE_MAIL,
    },
    approval_mail: {
      name: "Approval Mail",
      slug: process.env.VUE_APP_APPROVAL_MAIL,
    },
    reminder_mail: {
      name: "Reminder Mail",
      slug: process.env.VUE_APP_REMINDER_MAIL,
    },
    announcement: {
      name: "Announcements",
      slug: process.env.VUE_APP_MARKETING_ANNOUNCEMENT,
    },
    order_reservation: {
      name: "Order Reservation",
      slug: process.env.VUE_APP_ORDER_CONFIRMATION,
    },
    order_refund: {
      name: "Order Refund",
      slug: process.env.VUE_APP_ORDER_CONFIRMATION,
    },
    membership_purchase: {
      name: "Membership Purchase",
      slug: process.env.VUE_APP_ORDER_CONFIRMATION,
    },
    promo_mail: {
      name: "Promo Mail",
      slug: process.env.VUE_APP_PROMO_MAIL,
    },
    e_invoice_membership_mail: {
      name: "E Invoice Membership Mail",
      slug: process.env.VUE_APP_E_INVOICE_MAIL,
    },
    website_notification: {
      name: "Website Notification",
      slug: process.env.VUE_APP_WEBSITE_NOTIFICATION,
    },
    auto_debit_failure: {
      name: "Auto Debit Failure",
      slug: process.env.VUE_APP_AUTO_DEBIT_FAILURE,
    },
    event_cancel: {
      name: "Event cancel",
      slug: process.env.VUE_APP_EVENT_CANCEL,
    },
  },
  reports: {
    dashboard: {
      name: "Reports Dashboard",
      slug: process.env.VUE_APP_USER_PROFILE,
    },
    cashier: {
      name: "Cashier Sales Report",
      slug: process.env.VUE_APP_REPORTS_CASHIER,
    },
    service: {
      name: "Service Sales Report",
      slug: process.env.VUE_APP_REPORTS_SERVICES,
    },
    discount: {
      name: "Discount Sales Report",
      slug: process.env.VUE_APP_REPORTS_DISCOUNT,
    },
    bills: {
      name: "List Bill Report",
      slug: process.env.VUE_APP_REPORTS_BILLS,
    },
    payment: {
      name: "Payment Report",
      slug: process.env.VUE_APP_REPORTS_PAYMENTS,
    },
    product: {
      name: "Product Report",
      slug: process.env.VUE_APP_REPORTS_PRODUCTS,
    },
    trainer: {
      name: "Trainer Performance Report",
      slug: process.env.VUE_APP_REPORTS_TRAINER_PERFORMANCE,
    },
    attendance: {
      name: "Attendance Report",
      slug: process.env.VUE_APP_REPORTS_ATTENDANCE,
    },
    facility: {
      name: "Facility Usage Report",
      slug: process.env.VUE_APP_REPORTS_FACILITY_USAGE,
    },
    invoice: {
      name: "Open Invoice Report",
      slug: process.env.VUE_APP_REPORTS_OPEN_INVOICE,
    },
    cashier_audit: {
      name: "Cashier Audit Report",
      slug: process.env.VUE_APP_REPORTS_CASHIER_AUDIT,
    },
    programs: {
      name: "Program Report",
      slug: process.env.VUE_APP_REPORTS_PROGRAMS,
    },
    expiring_packages: {
      name: "Expiring Packages Report",
      slug: process.env.VUE_APP_REPORTS_EXPIRING_PACKAGES,
    },
    customer_demographic: {
      name: "Customer Demographic Report",
      slug: process.env.VUE_APP_REPORTS_CUSTOMER_DEMOGRAPHIC,
    },
    visitation: {
      name: "Visitation Report",
      slug: process.env.VUE_APP_REPORTS_VISITATION,
    },
    b2b: {
      name: "B2B Report",
      slug: process.env.VUE_APP_REPORTS_B2B,
    },
    website_sales: {
      name: "Qube Sales report",
      slug: process.env.VUE_APP_REPORTS_WSR,
    },
    pending_order: {
      name: "Pending Order Report",
      slug: process.env.VUE_APP_REPORTS_POR,
    },
    membership_sales: {
      name: "Membership Sales Report",
      slug: process.env.VUE_APP_REPORTS_MSR,
    },
    anonymous_visitation: {
      name: "Anonymous Visitation Report",
      slug: process.env.VUE_APP_ANONYMOUS_VISITATION,
    },
    visitation_analysis: {
      name: "Consolidated Visitation Analysis Report",
      slug: process.env.VUE_APP_VISITATION_ANALYSIS,
    },
    user_audit: {
      name: "User Audit Report",
      slug: process.env.VUE_APP_USER_AUDIT,
    },
    system_log: {
      name: "System Log Report",
      slug: process.env.VUE_APP_SYSTEM_LOG,
    },
    sales_log: {
      name: "Sales Log Report",
      slug: process.env.VUE_APP_SALES_LOG,
    },
    occupancy_rate: {
      name: "Occupancy Rate Report",
      slug: process.env.VUE_APP_REPORTS_OCCUPANCY_RATE,
    },
    membership_installment: {
      name: "Membership Installment Report",
      slug: process.env.VUE_APP_REPORTS_MIR,
    },
     customer_visitation: {
      name: "Customer Visitation Report",
      slug: process.env.VUE_APP_REPORTS_CVR,
    },

  },
  general: {
    dashboard: {
      name: "Dashboard",
      slug: process.env.VUE_APP_DASHBOARD,
    },
    profile: {
      name: "Venue Profile",
      slug: process.env.VUE_APP_VENUE_PROFILE,
    },
    services: {
      name: "Service",
      slug: process.env.VUE_APP_VENUE_SERVICES,
    },
    posts: {
      name: "Krews Post",
      slug: process.env.VUE_APP_KREWS_POST,
    },
    ai_sensy: {
      name: "AI Sensy (Whatsapp)",
      slug: process.env.VUE_APP_AI_SENSY,
    },
  },
  settings: {
    users: {
      name: "Venue User Management",
      slug: process.env.VUE_APP_SETTINGS_USERS,
    },
    roles: {
      name: "Venue Roles Management",
      slug: process.env.VUE_APP_SETTINGS_ROLES,
    },
    profile: {
      name: "User Profile",
      slug: process.env.VUE_APP_USER_PROFILE,
    },
  },
  corporate: {
    dashboard: {
      name: "Corporate Management",
      slug: process.env.VUE_APP_CORPORATE,
    },
    events: {
      name: "Corporate Sales",
      slug: process.env.VUE_APP_CORPORATE_SALES,
    },
  },
  kiosk: {
    facility: {
      name: "KIOSK Facility Booking",
      slug: process.env.VUE_APP_KIOSK_FACILITY,
    },
    event: {
      name: "KIOSK Event Booking",
      slug: process.env.VUE_APP_KIOSK_EVENT,
    },
    workshop: {
      name: "KIOSK Workshop Booking",
      slug: process.env.VUE_APP_KIOSK_WORKSHOP,
    },
  },
  b2b: {
    dashboard: {
      name: "B2B Dashboard Management",
      slug: process.env.VUE_APP_B2B_DASHBOARD,
    },
    partner: {
      name: "B2B Partner Management",
      slug: process.env.VUE_APP_B2B_PARTNER,
    },
  },
  pos: {
    dashboard: {
      name: "POS Dashboard",
      slug: process.env.VUE_APP_POS_DASHBOARD,
    },
    management: {
      name: "POS Management",
      slug: process.env.VUE_APP_POS_PRODUCT_MANAGEMENT,
    },
  },
  vcp: {
    management: {
      name: "Visitation Count Product",
      slug: process.env.VUE_APP_VISITATION_COUNT_PRODUCT,
    },
  },
  vwd: {
    management: {
      name: "Visitation Count without demography",
      slug: process.env.VUE_APP_VISITATION_WITHOUT_DEMOGRAPHY,
    },
  },
  outlet: {
    management: {
      name: "Outlet Management",
      slug: process.env.VUE_APP_OUTLET_MANAGEMENT,
    },
    commission: {
      name: "Outlet Commission",
      slug: process.env.VUE_APP_OUTLET_COMMISSION,
    },
    dashboard: {
      name: "Outlet Dashboard",
      slug: process.env.VUE_APP_OUTLET_DASHBOARD,
    },
    productsOrder: {
      name: "Outlet Products And Orders",
      slug: process.env.VUE_APP_OUTLET_PRODUCTS_AND_ORDERS,
    },
  },
  customerSupport: {
    support: {
      name: "Support Ticket Management",
      slug: process.env.VUE_APP_CUSTOMER_SUPPORT,
    },
  },
  salesTeam: {
    dashboard: {
      name: "Sales Team Dashboard",
      slug: process.env.VUE_APP_SALES_TEAM_DASHBOARD,
    },
    management: {
      name: "Sales Team Management",
      slug: process.env.VUE_APP_SALES_TEAM_MANAGEMENT,
    },
    commission: {
      name: "Sales Team Commission",
      slug: process.env.VUE_APP_SALES_TEAM_COMMISSION,
    },
    sales: {
      name: "Sales Team Sales",
      slug: process.env.VUE_APP_SALES_TEAM_SALES,
    },
    revenue: {
      name: "Sales Team Revenue",
      slug: process.env.VUE_APP_SALES_TEAM_EARNED_REVENUE,
    },
  },
  leadTracking: {
    management: {
      name: "Lead Tracking Management",
      slug: process.env.VUE_APP_LEAD_TRACKING_MANAGEMENT,
    },
    configuration: {
      name: "Lead Tracking Configuration",
      slug: process.env.VUE_APP_LEAD_TRACKING_CONFIGURATION,
    },
  },
  schedule:{
    configuration:{
      name:"Schedule Configuration",
      slug:process.env.VUE_APP_SCHEDULE_CONFIGURATION,
    },
    management:{
      name:"Schedule Management",
      slug:process.env.VUE_APP_SCHEDULE_MANAGEMENT,
    },
    availability:{
        name:"Schedule Online/Offline",
        slug:process.env.VUE_APP_SCHEDULE_AVAILABILITY,
    }
  },
  qpoints: {
    management: {
      name: "QPoints Management",
      slug: process.env.VUE_APP_QPOINTS_MANAGEMENT,
    },
  },
  shop:{
    dashboard:{
      name:"Shop",
      slug:process.env.VUE_APP_SHOP_DASHBOARD,
    }
  },
  offers: {
    management: {
      name: "Offers Management",
      slug: process.env.VUE_APP_OFFERS_MANAGEMENT,
    },
  },
  packages: {
    management: {
      name: "Packages Management",
      slug: process.env.VUE_APP_OFFERS_PACKAGE_MANAGEMENT,
    },
  },
  suggestions: {
    management: {
      name: "Suggestions Management",
      slug: process.env.VUE_APP_OFFERS_SUGGESTIONS_MANAGEMENT,
    },
  },
  crossSale: {
    management: {
      name: "Cross sale Management",
      slug: process.env.VUE_APP_OFFERS_CROSS_SALE_MANAGEMENT,
    },
  },
  masterPricing:{
    management:{
      name:"General Management",
      slug:process.env.VUE_APP_MASTER_PRICING_GENERAL_MANAGEMENT
    }
  },
  mediaLibrary:{
    management:{
      name:"General Management",
      slug:process.env.VUE_APP_MEDIA_LIBRARY_GENERAL_MANAGEMENT
    }
  },
};
export default Modules;
