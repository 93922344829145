<template>
  <v-app-bar
    app
    clipped-left
    :class="
      $store.getters.KioskEnabled ? 'top_bar transparent kiosk' : 'top_bar_new'
    "
    elevation="0"
    flat
  >
    <v-app-bar-nav-icon
      v-if="!$store.getters.KioskEnabled"
      @click.stop="hideSideBar"
    ></v-app-bar-nav-icon>
    <!-- <v-toolbar-title class="qp-title">QPortal</v-toolbar-title> -->
    <v-spacer></v-spacer>
    <div class="mr-4 text-center venue_name">
      {{ $store.getters.venueInfo.name }}
      <v-menu
        offset-y
        v-model="venuemenu"
        v-if="$store.getters.userVenues.length > 1"
        content-class="shadow" elevation="0" flat
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" fab style="background: none !important;" small variant="plain" elevation="0">
            <SvgIcon >
              <template v-slot:icon>
                <ArrowDownIcon/>
              </template>
            </SvgIcon>
          </v-btn>

        </template>
        <v-card>
          <v-list-item-group v-model="defaultVenue" color="teal">
            <v-list-item
              v-for="venue in $store.getters.userVenues"
              @click="switchVenue(venue)"
              :key="venue.id"
              link
              :value="venue.code"
              class="venue-list"
            >
              <v-list-item-avatar :tile="true" class="venue-image">
                <view-image :image="venue.profile_image" :contain="true" :height="40" :width="40" />
              </v-list-item-avatar>
              <v-list-item-content >
               <div class="d-flex justify-space-between align-center">
                 <v-list-item-title class="venue-title">
                   {{ venue.name }}
                 </v-list-item-title>
                 <v-list-item-icon class="my-0" v-if="venue.code == selectedVenue.code">
                   <CheckMarkIcon/>
                 </v-list-item-icon>
               </div>
              </v-list-item-content>

            </v-list-item>
          </v-list-item-group>
        </v-card>
      </v-menu>
    </div>
    <v-spacer></v-spacer>
    <template v-if="$store.getters.KioskEnabled">
      <!-- <v-btn @click="(logoutDialoge = true), (dashboardFlag = true)" text dark>
        Dashboard
      </v-btn> -->
      <v-btn
        class="kiosk-book-btn ma-2 white--text"
        @click="gotoKioskBookingForm()"
        >Book Now</v-btn
      >
      <v-btn @click="(logoutDialoge = true), (dashboardFlag = false)" text dark>
        <v-icon>mdi-logout-variant</v-icon>
      </v-btn>
    </template>
    <v-menu content-class="shadow" elevation="0" flat offset-y v-model="menu" v-else :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
       <div class="d-flex align-center">
         <p class="m-b-0 m-x-2 font-semibold text-lg text-blue">{{$store.getters.userInfo.first_name}} {{ $store.getters.userInfo.last_name}}</p>
         <v-btn v-bind="attrs" v-on="on" fab elevation="0" variant="plain">
           <v-avatar size="38">
             <view-image
                 :image="$store.getters.userInfo.image_path"
                 defaultImage="user"
                 :contain="false"
             ></view-image>
           </v-avatar>
         </v-btn>
       </div>
      </template>

      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                >{{ $store.getters.userInfo.first_name }}
                {{ $store.getters.userInfo.last_name }}</v-list-item-title
              >
              <v-list-item-subtitle>{{
                $store.getters.userInfo.role || "User Role"
              }}</v-list-item-subtitle>
              <v-list-item-subtitle>{{
                $store.getters.userInfo.email
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list dense>
          <v-list-item
            v-if="checkReadPermission($modules.settings.profile.slug)"
            link
            @click="gotoProfile"
            color="#062b47"
          >
            <v-list-item-icon>
              <v-icon color="menu_icon">mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Edit Profile</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="checkReadPermission($modules.settings.users.slug)"
            link
            @click="gotoUserSetting"
            color="#062b47"
          >
            <v-list-item-icon>
              <v-icon color="menu_icon">mdi-account-group</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Manage Users</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="checkReadPermission($modules.settings.roles.slug)"
            link
            @click="gotoRoleSetting"
            color="#062b47"
          >
            <v-list-item-icon>
              <v-icon color="menu_icon">mdi-account-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Manage Roles</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group
            no-action
            v-if="
              $store.getters.userRoles && $store.getters.userRoles.length > 1
            "
          >
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon color="#066a8c">mdi-account-switch</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title> Switch Role </v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              active-class="highlighted"
              :class="
                role.role_id === $store.getters.userInfo.role_id
                  ? 'v-list-item--active'
                  : ''
              "
              link
              @click="switchRole(role)"
              v-for="role in $store.getters.userRoles"
              :key="role.user_role_id"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ role.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item link color="#062b47">
            <v-list-item-icon>
              <v-icon color="menu_icon">mdi-theme-light-dark</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-if="$vuetify.theme.dark"
                >Dark Mode On</v-list-item-title
              >
              <v-list-item-title v-else>Dark Mode Off</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-switch v-model="switch1" @click="darkMode"></v-switch>
            </v-list-item-action>
          </v-list-item>

          <v-list-item link color="#062b47" @click="logOut">
            <v-list-item-icon>
              <v-icon color="menu_icon">mdi-logout-variant</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
    <log-out
      v-if="logoutDialoge"
      :logoutDialoge="logoutDialoge"
      @close="logoutDialoge = false"
      @confirm="logOutEvent"
      :label="dashboardFlag ? 'Switch' : 'Logout'"
    ></log-out>
  </v-app-bar>
</template>
<script>
import { EventBus } from "@/main";
import { mapGetters } from "vuex";
import ArrowDownIcon from '@/assets/images/nav_icon/arrow-down.svg'
import LogOut from "../../views/Kiosk/LogoutModel.vue";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import CheckMarkIcon from "@/assets/images/profile/checkmark.svg";

export default {
  data: () => ({
    drawer: true,
    menu: false,
    switch1: false,
    kiosk: false,
    defaultVenue: null,
    venuemenu: false,
    logoutDialoge: false,
    dashboardFlag: false,
  }),
  computed: {
    ...mapGetters({
      checkReadPermission: "checkReadPermission",
    }),
    selectedVenue() {
      return this.$store.getters.venueInfo;
    },
  },
  components: {
    SvgIcon,
    LogOut,
    ArrowDownIcon,
    CheckMarkIcon
  },
  mounted() {
    setTimeout(() => {
      this.defaultVenue = this.$store.getters.venueInfo.code;
    }, 10);
  },
  methods: {
    switchRole(role) {
      this.showLoader("Switching");
      this.$store
        .dispatch("switchRole", { role_id: role.role_id })
        .then((res) => {
          if (res.status == 200 && res.data.status) {
            setTimeout(() => {
              this.findFirstPage();
            }, 10);
            this.showSuccess(`Switched ${role.title} role successfully`);
            this.hideLoader();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    hideSideBar() {
      this.drawer = !this.drawer;
      EventBus.$emit("changeIt", this.drawer);
    },
    logOutEvent() {
      if (this.dashboardFlag) {
        this.kiosk = false;
        this.$store.dispatch("switchToKiosk", this.kiosk);
        if (this.$store.getters.userRoles.length > 1) {
          let modules = this.$store.getters.allPermissions.filter(
            (mod) => !mod.module_name.includes("KIOSK")
          );
          if (!modules.length) {
            let switchRoleId = this.$store.getters.userRoles.find(
              (role) => role.role_id != this.$store.getters.userInfo.role_id
            );
            if (switchRoleId) {
              this.switchRole(switchRoleId);
            }
          }
        } else {
          this.findFirstPage();
        }
        this.logoutDialoge = false;
      } else {
        this.logOut();
      }
    },
    logOut() {
      this.$store.dispatch("logout").then(() => {
        this.$store.dispatch('resetCart');
        this.kiosk = false;
        this.$store.dispatch("switchToKiosk", this.kiosk);
        localStorage.removeItem("kiosk");
        this.$router.push("/");
        // window.location.reload();
      });
    },
    gotoProfile() {
      this.$router.push({ name: "ProfileSettings" }, () => {});
      this.menu = false;
    },
    gotoUserSetting() {
      this.$router.push({ name: "UserSettings" }, () => {});
      this.menu = false;
    },
    gotoRoleSetting() {
      this.$router.push({ name: "RoleSettings" }, () => {});
      this.menu = false;
    },
    darkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },
    switchVenue(venue) {
      if (this.$store.getters.userInfo.venue_id == venue.id) {
        this.defaultVenue = this.$store.getters.userInfo.venue_id;
        return;
      }
      this.showLoader("Switching");
      this.$store
        .dispatch("switchVenue", { venue_id: venue.id })
        .then((res) => {
          if (res.status == 200 && res.data.status) {
            this.$store.commit("resetVenueState");
            this.$store.commit("resetGeneralState");
            this.$store.commit("resetFacilityState");
            this.$store.commit("resetOrderState");
            this.$store.commit("resetPaymentState");
            this.$store.commit("resetFacilitySchedule");
            this.$store.commit("resetConfiguration");
            setTimeout(() => {
              this.findFirstPage();
            }, 10);
            EventBus.$emit("reloadDashboard");
            this.showSuccess(`Switched to ${venue.name} successfully`);
            this.hideLoader();
            this.$forceUpdate();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    gotoKioskBookingForm() {
      if (this.$route.name != "BookingForm") {
        this.$router.push({
          name: "BookingForm",
        });
      }
    },
  },
};
</script>
<style scoped>
.venue_name {
  font-size: 18px;
  font-weight: 600;
}
header.top_bar.transparent {
  background-color: transparent !important;
  box-shadow: none !important;
}

header.top_bar button.kiosk-book-btn {
  width: 180px;
  height: 48px;
  font-size: 14px;
  font-weight: 600;
  background-color: #86e2ff !important;
  color: #012a37 !important;
}
header.top_bar_new{
  position: sticky !important;
  background: #FFF !important;
  border-bottom: 1px solid rgba(17, 42, 70, 0.10);;
}
.kiosk .qp-title {
  font-size: 28px;
  padding-left: 0px !important;
}
.kiosk .venue_name {
  font-size: 30px;
}
.kiosk button.top-logo-navbar-icon {
  width: 100px !important;
}
.kiosk .logo-img {
  width: 100px !important;
}

.venue-list{
  .venue-image {
    border-radius: 0.375rem !important;
    border: 0.5px solid #E3E3E3;
    background: #FFF;
  }

  .venue-title {
    font-size: 1rem;
    color: #112A46;
  }
}
</style>
