import axios from "../../utils/request";

const getFacilityState = () => {
  return {
    gameFormations: {},
    surfaces: {},
    timingTemplates: {},
    productCategories: {},
    isConfigurationLoaded: {},
    configuration: {},
    documents: {},
    bookingFields: {},
    rentalProducts: {},
  };
};

const state = getFacilityState();

const getters = {
  getGameFormationsByVenueServiceId: (state) => (venueServiceId) =>
    state.gameFormations[venueServiceId]
      ? state.gameFormations[venueServiceId]
      : [],
  getFacilitySurfaceByVenueServiceId: (state) => (venueServiceId) =>
    state.surfaces[venueServiceId] ? state.surfaces[venueServiceId] : [],
  getTimingTemplatesByVenueServiceId: (state) => (venueServiceId) =>
    state.timingTemplates[venueServiceId]
      ? state.timingTemplates[venueServiceId]
      : [],
  getProductCategoriesByVenueServiceId: (state) => (venueServiceId) =>
    state.productCategories[venueServiceId]
      ? state.productCategories[venueServiceId]
      : [],
  getRentalProductsByVenueServiceId: (state) => (venueServiceId) =>
    state.rentalProducts[venueServiceId]
      ? state.rentalProducts[venueServiceId]
      : [],
  getConfigurationByVenueServiceId: (state) => (venueServiceId) =>
    state.configuration[venueServiceId]
      ? state.configuration[venueServiceId]
      : [],
  getBookingFieldsByVenueServiceId: (state) => (venueServiceId) =>
    state.bookingFields[venueServiceId]
      ? state.bookingFields[venueServiceId]
      : [],
  getDocumentsByVenueServiceId: (state) => (venueServiceId) =>
    state.documents[venueServiceId] ? state.documents[venueServiceId] : [],
  getConfigurationStatus: (state) => (venueServiceId) =>
    state.isConfigurationLoaded[venueServiceId]
      ? state.isConfigurationLoaded[venueServiceId]
      : false,
};

const actions = {
  loadTimingTemplatesByVenueServiceId(context, venueServiceId) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `venues/facilities/timing-templates?venue_service_id=${venueServiceId}`
        )
        .then((response) => {
          if (response && response.status == 200 && response.data.status == true) {
            let data = {
              data: response.data.data,
              venue_service_id: venueServiceId,
            };
            context.commit("setTimingTemplates", data);
            resolve(response);
          }
          reject(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  loadRentalProductsByVenueServiceId(context, venueServiceId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`venues/facilities/categories/rentals/${venueServiceId}`)
        .then((response) => {
          if (response && response.status == 200 && response.data.status == true) {
            const data = response.data.data;
            let rentalData = {
              data: [],
              venue_service_id: venueServiceId,
            };
            if (data && data.length) {
              rentalData = {
                data: data,
                venue_service_id: venueServiceId,
              };
            }
            context.commit("setRentalProducts", rentalData);
            resolve(response);
          }
          reject(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  loadProductCategoriesByVenueServiceId(context, venueServiceId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`venues/facilities/categories/short/${venueServiceId}`)
        .then((response) => {
          if (response && response.status == 200 && response.data.status == true) {
            let data = {
              data: response.data.data,
              venue_service_id: venueServiceId,
            };
            context.commit("setProductCategories", data);
            resolve(response);
          }
          reject(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  loadConfigurationsByVenueServiceId(context, venueServiceId) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `venues/configurations?venue_service_id=${venueServiceId}&product_type=Facility`
        )
        .then((response) => {
          if (response && response.status == 200 && response.data.status == true) {
            const data = response.data.data;
            if (
              data.venue_service_configuration != null &&
              data.venue_service_configuration.per_capacity != null
            ) {
              data.venue_service_configuration.per_capacity.toString();
            }
            context.commit("setConfiguration", {
              data: data.venue_service_configuration,
              venue_service_id: venueServiceId,
            });
            context.commit("setDocuments", {
              data: data.venue_service_documents,
              venue_service_id: venueServiceId,
            });
            context.commit("setFields", {
              data: data.field_configurations,
              venue_service_id: venueServiceId,
            });
            context.commit("setGameFormations", {
              data: data.game_formations,
              venue_service_id: venueServiceId,
            });
            context.commit("setSurfaces", {
              data: data.facility_surface,
              venue_service_id: venueServiceId,
            });
            context.commit("setConfigurationStatus", venueServiceId);

            console.log('done')
            resolve(response);
          }
          reject(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

const mutations = {
  resetFacilityState(state) {
    Object.assign(state, getFacilityState());
  },
  setGameFormations: (state, data) => {
    state.gameFormations[data.venue_service_id] = data.data;
  },
  setSurfaces: (state, data) => {
    state.surfaces[data.venue_service_id] = data.data;
  },
  setTimingTemplates: (state, data) => {
    state.timingTemplates[data.venue_service_id] = data.data;
  },
  setRentalProducts: (state, data) => {
    state.rentalProducts[data.venue_service_id] = data.data;
  },
  setProductCategories: (state, data) => {
    state.productCategories[data.venue_service_id] = data.data;
  },
  setConfiguration: (state, data) => {
    state.configuration[data.venue_service_id] = data.data;
  },
  setDocuments: (state, data) => {
    state.documents[data.venue_service_id] = data.data;
  },
  setFields: (state, data) => {
    state.bookingFields[data.venue_service_id] = data.data;
  },
  setConfigurationStatus: (state, data) => {
    state.isConfigurationLoaded[data] = true;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
