import axios from "@/utils/request";
import Vue from 'vue';

const offerSearchUrl = "venues/shop/offers/search-product-offers";
const promotionSeachUrl = "venues/promotions/short?q=1";
const venueSwitchUrl = "venues/shop/switch-venue";

const getInitialCartState = () => {
    return {
        orders: [],
        payments: [
            {
                card_type_id: null, payment_code: null, payment_method_id: null, amount: null, payment_method: null,
            },
        ],
        customer: {},
        reloadShopModule: null,
        shopVenue: {},
        shopAvailableVenues: [],
        shopServices: {
            status: false, data: [],
        },
        shopPermissions: {
            status: false, data: {},
        },
        shopPromotions: {
            status: false, data: [],
        },
        offerProductsExcludedIds: [],
    };
};

const state = getInitialCartState();

const getters = {
    getCartCount: (state) => state.orders.length,
    getCartItems: (state) => state.orders,
    getReloadShopModule: (state) => state.reloadShopModule,
    getShopVenueServices: (state) => state.shopServices,
    getShopVenue: (state) => state.shopVenue,
    getShopAvailableVenues: (state) => state.shopAvailableVenues,
    getShopPermissions: (state) => state.shopPermissions,
    getShopPromotions: (state) => state.shopPromotions,
    checkWriteShopPermission: (state) => (slug) => {
        return state.shopPermissions.data && state.shopPermissions.data[slug] === 1;
    },
    offerProductsExcludedIds: (state) => state.offerProductsExcludedIds,

};

const actions = {
    shopSwitchVenue(context, venueId) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${venueSwitchUrl}/${venueId}`)
                .then((resp) => {
                    actionHandler(resp, context, resolve);
                    reject(resp);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    resetCart(context) {
        context.commit("resetCartState");
    },
    addToCart(context, data) {
        return new Promise((resolve, reject) => {
            context.commit("setAddToCart", data);
            resolve(data);
            reject({
                status: false, data: {message: "Failed add to cart",},
            });
        })
    },
    removeCartItem(context, data) {
        return new Promise((resolve, reject) => {
            context.commit("setRemoveCartItem", data);
            resolve(data);
            reject({
                status: false, data: {message: "Failed remove item from cart",},
            });
        });
    },
    addRetailToCart(context, data) {
        return new Promise((resolve, reject) => {
            context.commit("setRetailToCart", data);
            resolve(data);
            reject({
                status: false, data: {message: "Failed add to cart",},
            });
        })
    },
    updateRetailQtyCartItem(context, data) {
        return new Promise((resolve, reject) => {
            context.commit("setRetailQtyCartItem", data);
            resolve(data);
            reject({
                status: false, data: {message: "Failed remove item from cart",},
            });
        });
    },
    addRetailCustomer(context, data) {
        return new Promise((resolve, reject) => {
            context.commit("setRetailCustomer", data);
            resolve(data);
            reject({
                status: false, data: {message: "Failed customer set",},
            });
        })
    },
    addReloadShopModule(context, data) {
        return new Promise((resolve, reject) => {
            context.commit("setReloadShopModule", data);
            resolve(data);
            reject({
                status: false, data: {message: "Failed reload set",},
            });
        })
    },
    loadShopPromotions({commit}, params) {
        return new Promise((resolve, reject) => {
            let url = "";
            if (params) {
                if (params.venueId) {
                    url += "&venueId=" + params.venueId;
                }
                if (params.date) {
                    url += "&date=" + params.date;
                }
                if (params.venue_service_id) {
                    url += "&venue_service_id=" + params.venue_service_id;
                }
                if (params.trainer_id) {
                    url += "&trainer_id=" + params.trainer_id;
                }
                if (params.product_type) {
                    url += "&product_type=" + params.product_type;
                }
                if (params.membership_id) {
                    url += "&membership_id=" + params.membership_id;
                }
                if (params.product_id) {
                    url += "&product_id=" + params.product_id;
                }
            }

            axios
                .get(promotionSeachUrl + url)
                .then((response) => {
                    if (response && response.status === 200 && response.data.status === true) {
                        commit("setShopPromotions", response.data.data);
                        resolve(response);
                    }
                    reject(response);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    applyOfferToCartItem(context, data) {
        return new Promise((resolve, reject) => {
            context.commit("setOfferCartItem", data);
            resolve(data);
            reject({
                status: false, data: {message: "Failed remove item from cart",},
            });
        });
    },
    searchOffer({state},isProductExclude = true) {
        return new Promise((resolve, reject) => {
            let products = [];
            state.orders.forEach(item => {
                if (!item.discount ) {
                    item.products.forEach(product => {
                        if(isProductExclude){
                            if (!product.offer_package_id && !product.offer_package_id && !state.offerProductsExcludedIds.includes(product.product_id)) {
                                products.push({product_id: product.product_id, quantity: product.quantity});
                            }
                        }else{
                            if (!product.offer_package_id && !product.offer_package_id) {
                                products.push({product_id: product.product_id, quantity: product.quantity});
                            }
                        }

                    });
                }
            });
            if (products.length) {
                axios.post(offerSearchUrl, products)
                    .then((response) => {
                        if (response && response.status == 200 && response.data.status == true) {
                            resolve(response);
                        }
                        reject(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            }else{
                reject({
                    status: false, data: {message: "Product not available",},
                });
            }
        });
    },
    addOfferProductExcluded(context, data) {
        return new Promise((resolve, reject) => {
            context.commit("setOfferProductIds",data);
            resolve(data);
            reject({
                status: false, data: {message: "Failed reload set",},
            });
        })
    },
    removeOfferProductExcluded(context, data) {
        return new Promise((resolve, reject) => {
            context.commit("setRemoveOfferProductIds",data);
            resolve(data);
            reject({
                status: false, data: {message: "Failed reload set",},
            });
        })
    }

}
const mutations = {
    resetCartState(state) {
        Object.assign(state, getInitialCartState());
    },
    setAddToCart(state, data) {
        state.orders.push(data);
    },
    setRemoveCartItem(state, data) {
        // check if offer attach with product than reset other offer product as well
        let offer_package_id = null;
        if (state.orders[data.oi].products && state.orders[data.oi].products[data.pi]) {
            offer_package_id = state.orders[data.oi].products[data.pi].offer_package_id ? state.orders[data.oi].products[data.pi].offer_package_id : null;
        }
        if(state.offerProductsExcludedIds && state.offerProductsExcludedIds.length > 0) {
            if(state.orders[data.oi].products[data.pi] && state.orders[data.oi].products[data.pi].product_id){
                state.offerProductsExcludedIds.pop(state.orders[data.oi].products[data.pi].product_id);
            }
        }
        if (state.orders[data.oi].type === 'retail' || state.orders[data.oi].type === "event") {
            state.orders[data.oi].products.splice(data.pi, 1);
            if (state.orders[data.oi].products.length === 0) {
                state.orders.splice(data.oi, 1);
            }
        } else if (state.orders[data.oi].type === 'facility' && state.orders[data.oi].products.length > 0 && state.orders[data.oi].products[data.pi].rental !== true) {
            state.orders[data.oi].products.splice(data.pi, 1);
        } else {
            state.orders.splice(data.oi, 1);
        }
        if (offer_package_id) {
            this.commit("removeOffer", offer_package_id);
        }

    },
    setRetailToCart(state, data) {
        let cartItemIndex = state.orders.findIndex((item) => item.type == "retail" && item.venueId == data.venueId);
        if (cartItemIndex >= 0) {
            state.orders[cartItemIndex].products.push(data.product);
        } else {
            let d = {products: [], type: "retail", venueId: data.venueId};
            d.products.push(data.product);
            state.orders.push(d);
        }
    },
    setRetailQtyCartItem(state, data) {
        let product = state.orders[data.oi].products[data.pi];
        let offer_package_id = product.offer_package_id || null;
        let offer = product.offer || null;
        let isRemoveOffer = false;

        if (product.quantity > 1) {
            state.orders[data.oi].products[data.pi].quantity = product.quantity - 1;

            // Check if we should remove the offer
            if (offer_package_id && offer && (product.quantity - 1 < offer.quantity)) {
                isRemoveOffer = true;
            }
        } else {
            isRemoveOffer = true;
            state.orders[data.oi].products.splice(data.pi, 1);
        }

        // Remove the order if no products are left
        if (state.orders[data.oi].products.length === 0) {
            state.orders.splice(data.oi, 1);
        }

        // Handle offer removal if necessary
        if (offer_package_id && isRemoveOffer) {
            this.commit("removeOffer", offer_package_id);
        }
    },
    setRetailCustomer(state, data) {
        let cartItemIndex = state.orders.findIndex((item) => item.type == "retail" && item.venueId == data.venueId);
        if (cartItemIndex >= 0) {
            if (state.orders[cartItemIndex].customers) {
                state.orders[cartItemIndex].customers = data;
                state.orders[cartItemIndex].venueId = data;
            }
        } else {
            let d = {
                customers: [],
                products: [],
                type: "retail",
                venueId: data.venueId,
                venue_service_id: data.venue_service_id
            };
            d.customers = data.customers;
            state.orders.push(d);
        }
    },

    setReloadShopModule(state, data) {
        state.reloadShopModule = data;
    },

    setShopVenue(state, data) {
        state.shopVenue = data;
    },

    setShopAvailableVenues(state, data) {
        state.shopAvailableVenues = data;
    },

    setShopServices(state, data) {
        state.shopServices.status = true;
        state.shopServices.data = data;
    },

    setShopPermissions(state, data) {
        state.shopPermissions.status = true;
        state.shopPermissions.data = data;
    },

    setShopPromotions: (state, stages) => {
        state.shopPromotions.status = true;
        state.shopPromotions.data = stages;
    },

    setOfferCartItem(state, data) {
        data.forEach((product) => {

            state.orders.forEach((order) => {
                if (order.offer_package_id) {
                    order.offer_package_id = null;
                }
                const productIndex = order.products.findIndex(item => order.venueId === product.venue_id && item.product_id === product.product_id);
                if (productIndex >= 0) {
                    if(order.type !== "retail") {
                        order.offer_package_id = product.offer_package_id;
                    }
                    const selectedProduct = order.products[productIndex];
                    //Use Vue.set to ensure reactivity
                    Vue.set(selectedProduct, 'offer', {...product});
                    Vue.set(selectedProduct, 'offer_package_id', product.offer_package_id);

                    if (selectedProduct.discount) {
                        Vue.set(selectedProduct, 'discount', null);
                    }
                }
            });
        });
    },

    removeOffer(state, offer_package_id) {
        if (offer_package_id) {
            state.orders.forEach((order) => {
                order.products.forEach((product) => {
                    if (product.offer_package_id === offer_package_id) {
                        product.offer_package_id = null;
                        product.offer = null;
                    }
                })
            })
        }
    },

    setOfferProductIds(state,productIds){
        if (productIds && productIds.length > 0) {
            productIds.forEach( (productId) => {
                if(state.offerProductsExcludedIds.findIndex((item) => item === productId) === -1){
                    state.offerProductsExcludedIds.push(productId);
                }
            });
        }
    },
    setRemoveOfferProductIds(state,productIds){
        if (productIds && productIds.length > 0) {
            productIds.forEach( (productId) => {
                if(state.offerProductsExcludedIds.findIndex((item) => item === productId) !== -1){
                    state.offerProductsExcludedIds.pop(productId);
                }
            });
        }
    }
};

const actionHandler = (resp, context, resolve) => {
    if (resp.status === 200 && resp.data.status === true) {
        const response = resp.data.data;
        let venue = {};
        if (response.venue) {
            venue = {
                id: response.venue.id, name: response.venue.name,
            };
        }
        const availableVenues = response.venues;
        const permissions = response.permissions;
        const services = response.services;
        if (!permissions) {
            context.commit("SHOW_NOTIFICATION", {
                text: "No permissions", type: "error", timeout: 10000,
            });
        }


        //Set New Values to Local Storage
        context.commit("setShopVenue", venue);
        context.commit("setShopAvailableVenues", availableVenues);
        context.commit("setShopPermissions", permissions);
        context.commit("setShopServices", services);
        resolve(resp);
    }
};
export default {
    state, getters, actions, mutations,
};
