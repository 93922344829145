import Vue from "vue";
import VueRouter from "vue-router";
import Modules from "../utils/moduleStructure";
import MasterPricing from "@/views/MasterPricing/MasterPricing.vue";
import MediaLibrary from "@/views/MediaLibrary/MediaLibrary.vue";

const Login = () => import("../views/Login/Login");
const LoginSSO = () => import("../views/Login/LoginSSO");

const ProfileSettings = () => import("../views/Settings/ProfileSettings");
const RoleSettings = () => import("../views/Settings/RoleSettings");
const UserSettings = () => import("../views/Settings/UserSettings");

const Schedule = () => import("../views/Schedule/Facility/Schedule");
const ScheduleConfiguration = () => import("../views/Schedule/Facility/ScheduleConfiguration");
const GolfSchedule = () => import("../views/Schedule/Facility/Golf/GolfSchedule");

const Calendar = () => import("../views/Schedule/Facility/Calendar");

const EventSchedule = () => import("../views/Schedule/Event/EventSchedule.vue");
const EventCalendar = () => import("../views/Schedule/Event/EventCalendar");

const WorkshopSchedule = () => import("../views/Schedule/Workshop/WorkshopSchedule.vue");
const WorkshopCalendar = () => import("../views/Schedule/Workshop/WorkshopCalendar");

const Facilities = () => import("../views/Facility/Facilities");
const FacilityForm = () => import("../views/Facility/FacilityForm");
const RentalTicketProducts = () => import("../views/Facility/RentalTicketProducts");
const FacilityConfiguration = () => import("../views/Facility/FacilityConfiguration");
const ViewFacility = () => import("../views/Facility/ViewFacility");
const FacilityProducts = () => import("../views/Facility/FacilityProducts");

const Trainers = () => import("../views/Trainers/Trainers");
const TrainerSales = () => import("../views/Trainers/TrainerSales");
const TrainerCustomers = () => import("../views/Trainers/TrainerCustomers");
const TrainerConfiguration = () => import("../views/Trainers/TrainerConfiguration");

const Spaces = () => import("../views/Space/Spaces");
const SpaceMaps = () => import("../views/Space/SpaceMaps");
const SeatMapConfig = () => import("../views/Space/SeatMapConfig");

const Promotions = () => import("../views/Promotions/Promotions");
const ViewPromotion = () => import("../views/Promotions/ViewPromotion.vue");
const PromotionsForm = () => import("../views/Promotions/PromotionsForm");
const PromotionsManage = () => import("../views/Promotions/PromotionsManage");

const Vouchers = () => import("../views/Vouchers/Vouchers");
const VoucherSales = () => import("../views/Vouchers/VoucherSales");

const Events = () => import("../views/Events/Events");
const EventForm = () => import("../views/Events/EventForm");
const EventConfigurations = () => import("../views/Events/EventConfigurations");
const ViewEvent = () => import("../views/Events/ViewEvent");

const VenueProfile = () => import("../views/Venue/VenueProfile");

const Customers = () => import("../views/Clients/Customers.vue");
const CustomerGraph = () => import("../views/Clients/CustomerGraph.vue");
const CustomerConfiguration = () => import("../views/Clients/CustomerConfiguration.vue");
const CustomersMerging = () => import("../views/Clients/CustomersMerging.vue");
const CustomerLoginApprovals = () => import("../views/Clients/CustomerLoginApprovals.vue");

const CorporateDashboard = () => import("../views/Company/Company.vue");
const CorporateEventOrders = () => import("../views/Company/OrderSalesView.vue");

const NotFoundPage = () => import("../views/Errors/NotFound.vue");
const Forbidden = () => import("../views/Errors/Forbidden.vue");

const Sales = () => import("../views/Sales/Sales");
const Logs = () => import("../views/Sales/Logs");
const Invoices = () => import("../views/Sales/OrderInvoices.vue");
const KrewsWallet = () => import("../views/Sales/KrewsWallet");
const OnlineWallet = () => import("../views/Sales/OnlineWallet");

const SocialPosts = () => import("../views/Marketing/SocialPosts");
const ConfigureEmailTemplate = () => import("../views/Marketing/ConfigureEmailTemplate.vue");
const MailSMSDetails = () => import("../views/Marketing/MailSMSDetails");
const AddSMSEmailMarketing = () => import("../views/Marketing/AddSMSEmailMarketing");
const AnnouncementDetails = () => import("../views/Marketing/AnnouncementDetails");
const WebsiteNotification = () => import("../views/Marketing/WebsiteNotifications");
const AddAnnouncement = () => import("../views/Marketing/AddAnnouncement");
const WebsiteNotificationForm = () => import("../views/Marketing/WebsiteNotificationForm.vue");
const Newsletter = () => import("../views/Marketing/Newsletter");
const OrderConfirmationMail = () => import("../views/Marketing/OrderConfirmationMail");
const OrderCancelMail = () => import("../views/Marketing/OrderCancelMail");
const OrderChangeMail = () => import("../views/Marketing/OrderChangeMail");
const WelcomeMail = () => import("../views/Marketing/WelcomeMail");
const NewMemberMail = () => import("../views/Marketing/NewMemberMail");
const RenewMemberMail = () => import("../views/Marketing/RenewMemberMail");
const SurveyMail = () => import("../views/Marketing/SurveyMail");
const EInvoice = () => import("../views/Marketing/EInvoiceMail");
const ApprovalMail = () => import("../views/Marketing/ApprovalMail");
const ReminderMail = () => import("../views/Marketing/ReminderMail");
const Marketing = () => import("../views/Marketing/Marketing");

const Dashboard = () => import("../views/Dashboard/Dashboard.vue");

const KioskDashboard = () => import("../views/Kiosk/KioskDashboard.vue");
const BookingForm = () => import("../views/Kiosk/BookingForm.vue");

const Memberships = () => import("../views/Memberships/Memberships");
const MembershipSales = () => import("../views/Memberships/MembershipSales");
const MembershipAttendanceLog = () => import("../views/Memberships/MembershipAttendanceLog");
const MembershipAttendance = () => import("../views/Memberships/MembershipAttendance");
const MembershipAnalytics = () => import("../views/Memberships/MembershipAnalytics");
const MembershipsForm = () => import("../views/Memberships/MembershipsForm");
const MembershipView = () => import("../views/Memberships/MembershipView");
const MembershipLogs = () => import("../views/Memberships/MembershipLogs");
const MembershipConfiguration = () => import("../views/Memberships/MembershipConfiguration.vue");

const Reports = () => import("../views/Reports/Reports");
const VenueServiceReport = () => import("../views/Reports/VenueServiceReport");
const ProductSalesReport = () => import("../views/Reports/ProductSalesReport");
const ListBillReport = () => import("../views/Reports/ListBillReport.vue");
const CashierReport = () => import("../views/Reports/CashierReport.vue");
const DiscountReport = () => import("../views/Reports/DiscountReport.vue");
const PaymentTypeSalesReport = () => import("../views/Reports/PaymentTypeSalesReport.vue");
const FacilityUsageReport = () => import("../views/Reports/FacilityUsageReport.vue");
const AttendanceReport = () => import("../views/Reports/AttendanceReport.vue");
const TrainerPerformanceReport = () => import("../views/Reports/TrainerPerformanceReport.vue");
const OpenInvoiceReport = () => import("../views/Reports/OpenInvoiceReport.vue");
const CashierAuditReport = () => import("../views/Reports/CashierAuditReport.vue");
const ProgramReport = () => import("../views/Reports/ProgramReport.vue");
const ExpiringPackageReport = () => import("../views/Reports/ExpiringPackageReport.vue");
const OnlineSalesSettlementReport = () => import("../views/Reports/OnlineSalesSettlementReport.vue");
const CustomerDemographicReport = () => import("../views/Reports/CustomerDemographicReport.vue");
const VisitationReport = () => import("../views/Reports/VisitationReport.vue");
const B2BReport = () => import("../views/Reports/B2BReport.vue");
const AnonymousVisitationReport = () => import("../views/Reports/AnonymousVisitationReport.vue");
const MembershipSalesReport = () => import("../views/Reports/MembershipSalesReport.vue");
const WebsiteOnlineReport = () => import("../views/Reports/WebsiteOnlineReport.vue");
const PendingOrderReport = () => import("../views/Reports/PendingOrderReport.vue");
const Workshops = () => import("../views/Workshops/Workshops");
const WorkshopForm = () => import("../views/Workshops/WorkshopForm");
const WorkshopSales = () => import("../views/Workshops/WorkshopSales");
const ViewWorkshop = () => import("../views/Workshops/ViewWorkshop");
const WorkshopConfiguration = () => import("../views/Workshops/configuration.vue");
const VisitationCountReport = () => import("../views/Reports/VisitationCountReport.vue");
const UserAuditReport = () => import("../views/Reports/UserAuditReport.vue");
const SystemLogReport = () => import("../views/Reports/SystemLogReport.vue");
const SalesLogReport = () => import("../views/Reports/SalesLogReport.vue");
const OccupancyRateReport = () => import("../views/Reports/OccupancyRateReport.vue");
const MembershipInstallmentReport = () => import("../views/Reports/MembershipInstallmentReport");
const CustomerVisitationReport = () => import("../views/Reports/CustomerVisitationReport");


const B2CGeneralConfig = () => import("../views/B2CConfigurations/B2CGeneralConfig.vue");
const B2CPagesConfig = () => import("../views/B2CConfigurations/B2CPagesConfig.vue");
const B2CBookingsConfig = () => import("../views/B2CConfigurations/B2CBookingsConfig.vue");
const QubeAppConfig = () => import("../views/B2CConfigurations/QubeAppConfig.vue");

const B2CSportsConfig = () => import("../views/B2CConfigurations/B2CSportsConfig.vue");
const B2CAdditionalPage = () => import("../views/B2CConfigurations/B2CAdditionalPage.vue");
const B2CMenu = () => import("../views/B2CConfigurations/B2CMenu.vue");

/** card reader testing url */
const CardReader = () => import("../views/Demo/CardReader.vue");
const B2bDashboard = () => import("../views/B2b/B2bDashboard.vue");
const B2bPartners = () => import("../views/B2b/B2bPartners.vue");
const B2bProducts = () => import("../views/B2b/B2bProducts.vue");
const B2bPartnerProducts = () => import("../views/B2b/B2bPartnerProducts.vue");
const B2bPartnersView = () => import("../views/B2b/B2bPartnersView.vue");
const B2bConfiguration = () => import("../views/B2b/B2bConfiguration.vue");

const Outlets = () => import("../views/Outlets/Outlets");
const OutletView = () => import("../views/Outlets/OutletView");
const OutletProductManagement = () => import("../views/Outlets/OutletProductManagement");

/** POS Module */
const PosView = () => import("../views/Pos/PosView.vue");
const ProductManagement = () => import("../views/Pos/ProductManagement.vue");
const CustomerSupport = () =>  import("@/views/CustomerSupport/CustomerSupport.vue");
const CreateSupportTicket = () =>  import("@/views/CustomerSupport/CreateSupportTicket.vue");
const CustomerSupportDetails = () =>  import("@/views/CustomerSupport/CustomerSupportDetails.vue");
const OrderReservationMail = () =>  import("@/views/Marketing/OrderReservationMail.vue");
const OrderRefundMail = () =>  import("@/views/Marketing/OrderRefundMail.vue");
const MembershipPurchaseMail = () =>  import("@/views/Marketing/MembershipPurchaseMail.vue");

//*** Sales Team */

const SalesTeams = () =>  import("../views/SalesTeam/SalesTeams");
const SalesTeamView = () =>  import("../views/SalesTeam/SalesTeamView");
const PromoMail = () =>  import("@/views/Marketing/PromoMail.vue");

// Lead Tracking

const LeadsDashboard = () =>  import("../views/LeadTracking/LeadsDashboard.vue");
const LeadsFormConfiguration = () =>  import("../views/LeadTracking/LeadsFormConfiguration.vue");

const QPointsDashboard = () =>  import("../views/QPoints/QPointsDashboard.vue");
const QPointsLogs = () =>  import("../views/QPoints/QPointsLogs.vue");
const QPointsConfig = () =>  import("../views/QPoints/QPointsConfig.vue");
const B2CBlogs = () => import('@/views/B2CConfigurations/B2CBlogs.vue')
const BlogForm = () => import("@/components/B2C/BlogForm.vue");


const Offers = () => import("../views/Offers/Offers.vue");
const Suggestions = () => import("../views/Offers/Suggestions.vue");
const CrossSale = () => import("../views/Offers/CrossSale.vue");
const ShopFacility = () => import("@/views/Shop/ShopFacility.vue");
const ShopEvents = () => import("@/views/Shop/ShopEvents.vue");
const ShopMemberships = () => import("@/views/Shop/ShopMemberships.vue");
const ShopTrainers = () => import("@/views/Shop/ShopTrainers.vue");
const ShopAcademies = () => import("@/views/Shop/ShopAcademy.vue");
const ShopRetail = () => import("@/views/Shop/ShopRetail.vue");
const ShopOffers = () => import("@/views/Shop/ShopOffers.vue");
const ShopCart = () => import("@/views/Shop/ShopCart.vue");

const KioskConfig = () => import("@/views/B2CConfigurations/KioskConfig.vue");

Vue.use(VueRouter);
const routes = [
  //Login
  {
    name: "Root",
    path: "/",
    component: Login,
    meta: {
      item: null,
      module: {
        id: null,
      },
      title: "Login",
    },
  },
  {
    name: "Login",
    path: "/login",
    component: Login,
    meta: {
      item: null,
      module: {
        id: null,
      },
      title: "Login",
    },
  },
  {
    name: "LoginAD",
    path: "/login-sso-success",
    component: LoginSSO,
    meta: {
      item: null,
      module: {
        id: null,
      },
      title: "Login SSO",
    },
  },

  //Setting Pages
  {
    name: "ProfileSettings",
    path: "/settings/profile",
    component: ProfileSettings,
    meta: {
      item: "profile",
      module: Modules.settings.profile,
      title: "Profile",
    },
  },

  {
    name: "RoleSettings",
    path: "/settings/roles",
    component: RoleSettings,
    meta: {
      item: "profile",
      module: Modules.settings.roles,
      title: "Profile",
    },
  },

  {
    name: "UserSettings",
    path: "/settings/users",
    component: UserSettings,
    meta: {
      item: "profile",
      module: Modules.settings.users,
      title: "Profile",
    },
  },

  {
    name: "Schedule",
    path: "/schedule",
    component: Schedule,
    meta: {
      item: "schedule",
      module: {
        id: Modules.facility.schedule,
      },
      title: "Schedule",
    },
  },
  {
    name: "ScheduleConfiguration",
    path: "/schedule-configuration/:data",
    component: ScheduleConfiguration,
    meta: {
      item: "schedule",
      module: {
        id: Modules.facility.schedule,
      },
      title: "Schedule",
    },
  },
  {
    name: "GolfSchedule",
    path: "/golf-schedule",
    component: GolfSchedule,
    meta: {
      item: "schedule",
      module: {
        id: Modules.facility.schedule,
      },
      title: "Golf Schedule",
    },
  },
  {
    name: "Calendar",
    path: "/calender",
    component: Calendar,
    meta: {
      item: "schedule",
      module: {
        id: Modules.facility.schedule,
      },
      title: "Schedule",
    },
  },
  {
    name: "CalendarWithParams",
    path: "/calender/:data",
    component: Calendar,
    meta: {
      item: "schedule",
      module: {
        id: Modules.facility.schedule,
      },
      title: "Schedule",
    },
  },
  {
    name: "ScheduleWithParams",
    path: "/schedule/:data",
    component: Schedule,
    meta: {
      item: "schedule",
      module: {
        id: Modules.facility.schedule,
      },
      title: "Schedule",
    },
  },

  {
    name: "EventSchedule",
    path: "/event-schedule",
    component: EventSchedule,
    meta: {
      item: "schedule",
      module: {
        id: Modules.events.schedule,
      },
      title: "Schedule",
    },
  },

  {
    name: "EventScheduleWithParams",
    path: "/event-schedule/:data",
    component: EventSchedule,
    meta: {
      item: "schedule",
      module: {
        id: Modules.events.schedule,
      },
      title: "Schedule",
    },
  },

  {
    name: "EventCalendar",
    path: "/calendar/events",
    component: EventCalendar,
    meta: {
      item: "schedule",
      module: {
        id: Modules.events.schedule,
      },
      title: "Schedule",
    },
  },

  {
    name: "EventCalendarWithParams",
    path: "/calendar/events/:data",
    component: EventCalendar,
    meta: {
      item: "schedule",
      module: {
        id: Modules.events.schedule,
      },
      title: "Schedule",
    },
  },

  {
    name: "WorkshopSchedule",
    path: "/workshop-schedule",
    component: WorkshopSchedule,
    meta: {
      item: "schedule",
      module: {
        id: Modules.workshops.schedule,
      },
      title: "Schedule",
    },
  },

  {
    name: "WorkshopCalendar",
    path: "/workshopCalender",
    component: WorkshopCalendar,
    meta: {
      item: "schedule",
      module: {
        id: Modules.events.schedule,
      },
      title: "Schedule",
    },
  },

  {
    name: "Facilities",
    path: "/facilities",
    component: Facilities,
    meta: {
      item: "grounds",
      module: {
        id: Modules.facility.management,
      },
      title: "Facility",
    },
  },
  {
    name: "FacilityPerService",
    path: "/facilities/:data",
    component: Facilities,
    meta: {
      item: "grounds",
      module: {
        id: Modules.facility.management,
      },
      title: "Facility",
    },
  },

  {
    name: "TickerRentalProduct",
    path: "/ticket-rentals",
    component: RentalTicketProducts,
    meta: {
      item: "grounds",
      module: {
        id: Modules.facility.management,
      },
      title: "Facility",
    },
  },
  {
    name: "TickerRentalProductPerService",
    path: "/ticket-rentals/:data",
    component: RentalTicketProducts,
    meta: {
      item: "grounds",
      module: {
        id: Modules.facility.management,
      },
      title: "Facility",
    },
  },

  {
    name: "FacilityForm",
    path: "/facilities/form/:data",
    component: FacilityForm,
    props: true,
    meta: {
      item: "grounds",
      module: {
        id: Modules.facility.management,
      },
      title: "Facility",
    },
  },
  {
    name: "FacilityConfiguration",
    path: "/facilities/configurations/:data",
    component: FacilityConfiguration,
    meta: {
      item: "grounds",
      module: {
        id: Modules.facility.management,
      },
      title: "Facility",
    },
  },
  {
    name: "ViewFacility",
    path: "/facility-view/:data",
    component: ViewFacility,
    meta: {
      item: "grounds",
      module: {
        id: Modules.facility.management,
      },
      title: "Facility",
    },
  },
  {
    name: "FacilityProducts",
    path: "/facilities/products",
    component: FacilityProducts,
    meta: {
      item: "grounds",
      module: {
        id: Modules.facility.management,
      },
      title: "Facility",
    },
  },

  {
    name: "FacilityProductsPerService",
    path: "/facilities/products/:data",
    component: FacilityProducts,
    meta: {
      item: "grounds",
      module: {
        id: Modules.facility.management,
      },
      title: "Facility",
    },
  },

  {
    name: "Trainers",
    path: "/trainers",
    component: Trainers,
    meta: {
      item: "trainers",
      module: Modules.trainers.dashboard,
      title: "Trainers",
    },
  },

  {
    name: "TrainerSales",
    path: "/trainer-sales",
    component: TrainerSales,
    meta: {
      item: "trainers",
      module: Modules.trainers.dashboard,
      title: "Trainer Sales",
    },
  },

  {
    name: "TrainerCustomers",
    path: "/trainers/customers/:data",
    component: TrainerCustomers,
    meta: {
      item: "trainers",
      module: Modules.trainers.dashboard,
      title: "Trainer Customers",
    },
  },

  {
    name: "TrainerConfiguration",
    path: "/trainers/configuration",
    component: TrainerConfiguration,
    meta: {
      item: "trainers",
      module: Modules.trainers.dashboard,
      title: "Trainer configuration",
    },
  },

  {
    name: "Profile",
    path: "/venue/profile",
    component: VenueProfile,
    meta: {
      item: "profile",
      module: Modules.general.profile,
      title: "Profile",
    },
  },

  {
    name: "Spaces",
    path: "/space",
    component: Spaces,
    meta: {
      item: "spaces",
      module: Modules.space.management,
      title: "Spaces",
    },
  },
  {
    name: "SpaceMaps",
    path: "/space/maps/:data",
    component: SpaceMaps,
    meta: {
      item: "Space Maps",
      module: Modules.space.management,
      title: "Space Maps",
    },
  },
  {
    name: "SeatMapConfig",
    path: "/seat-map/config/:data",
    component: SeatMapConfig,
    meta: {
      item: "SeatMap Config",
      module: Modules.space.management,
      title: "Seat Map Config",
    },
  },
  {
    name: "Vouchers",
    path: "/vouchers",
    component: Vouchers,
    meta: {
      item: "vouchers",
      module: Modules.vouchers.management,
      title: "Vouchers",
    },
  },

  {
    name: "VoucherSales",
    path: "/vouchers-sales/:data",
    component: VoucherSales,
    meta: {
      item: "vouchers",
      module: Modules.vouchers.issues,
      title: "Vouchers",
    },
  },

  {
    name: "AddPromotion",
    path: "/promotions/add",
    component: PromotionsForm,
    meta: {
      item: "promotions",
      module: Modules.promotions.management,
      title: "Promotions",
    },
  },

  {
    name: "ManagePromotion",
    path: "/promotions/manage/:data",
    component: PromotionsManage,
    meta: {
      item: "promotions",
      module: Modules.promotions.management,
      title: "Promotions",
    },
  },

  {
    name: "EditPromotion",
    path: "/promotions/edit/:data",
    component: PromotionsForm,
    meta: {
      item: "promotions",
      module: Modules.promotions.management,
      title: "Promotions",
    },
  },

  {
    name: "Promotions",
    path: "/promotions",
    component: Promotions,
    meta: {
      item: "promotions",
      module: Modules.promotions.management,
      title: "Promotions",
    },
  },
  {
    name: "ViewPromotion",
    path: "/promotion/view/:data",
    component: ViewPromotion,
    meta: {
      item: "promotions",
      module: Modules.promotions.management,
      title: "Promotions",
    },
  },

  {
    name: "Customers",
    path: "/customers",
    component: Customers,
    meta: {
      item: "clients",
      module: Modules.clients.customers,
      title: "Client",
    },
  },
{
    name: "CustomerLoginApprovals",
    path: "/customers/login-approvals",
    component: CustomerLoginApprovals,
    meta: {
      item: "clients",
      module: Modules.clients.customers,
      title: "Client",
    },
  },
  {
    name: "CustomerGraph",
    path: "/customers/graphs",
    component: CustomerGraph,
    meta: {
      item: "clients",
      module: Modules.clients.customers,
      title: "Client",
    },
  },

  {
    name: "CustomerConfigurations",
    path: "/customers/configuration",
    component: CustomerConfiguration,
    meta: {
      item: "clients",
      module: Modules.clients.customers,
      title: "Client Configurations",
    },
  },

  {
    name: "CustomersMerging",
    path: "/customers/merging",
    component: CustomersMerging,
    meta: {
      item: "clients",
      module: Modules.clients.customers,
      title: "Customer Merging",
    },
  },

  {
    name: "CorporateDashboard",
    path: "/corporates",
    component: CorporateDashboard,
    meta: {
      item: "corporates",
      module: Modules.corporate.dashboard,
      title: "Corporate Dashboard",
    },
  },
  {
    name: "CorporateEventOrders",
    path: "/corporates/orders/:id",
    component: CorporateEventOrders,
    meta: {
      item: "corporates",
      module: Modules.corporate.events,
      title: "Corporate Event Orders",
    },
  },

  {
    name: "Events",
    path: "/events",
    component: Events,
    meta: {
      item: "events",
      module: Modules.events.management, //
      title: "Events",
    },
  },
  {
    name: "EventsAdd",
    path: "/events/add",
    component: EventForm,
    meta: {
      item: "events",
      module: Modules.events.management,
      title: "Add Events",
    },
  },
  {
    name: "EventsConfigurations",
    path: "/events/configuration",
    component: EventConfigurations,
    meta: {
      item: "events",
      module: Modules.events.management,
      title: "Events Configurations",
    },
  },
  {
    name: "EventsEdit",
    path: "/events/edit/:data",
    component: EventForm,
    meta: {
      item: "events",
      module: Modules.events.management,
      title: "Edit Events",
    },
  },
  {
    name: "EventsView",
    path: "/events/details/:data",
    component: ViewEvent,
    meta: {
      item: "events",
      module: Modules.events.management,
      title: "Events",
    },
  },

  {
    name: "Memberships",
    path: "/memberships",
    component: Memberships,
    meta: {
      item: "memberships",
      module: Modules.memberships.dashboard,
      title: "Memberships",
    },
  },

  {
    name: "MembershipSales",
    path: "/memberships/sales",
    component: MembershipSales,
    meta: {
      item: "memberships",
      module: Modules.memberships.dashboard,
      title: "Memberships",
    },
  },
  {
    name: "MembershipAttendanceLog",
    path: "/memberships/attendance-log",
    component: MembershipAttendanceLog,
    meta: {
      item: "memberships",
      module: Modules.memberships.schedule,
      title: "Memberships",
    },
  },
  {
    name: "MembershipAttendance",
    path: "/memberships/attendance",
    component: MembershipAttendance,
    meta: {
      item: "memberships",
      module: Modules.memberships.schedule,
      title: "Memberships",
    },
  },
  {
    name: "MembershipAnalytics",
    path: "/memberships/analytics",
    component: MembershipAnalytics,
    meta: {
      item: "memberships",
      module: Modules.memberships.schedule,
      title: "Memberships",
    },
  },
  {
    name: "MembershipAdd",
    path: "/memberships/add",
    component: MembershipsForm,
    meta: {
      item: "memberships",
      module: Modules.memberships.management,
      title: "Memberships",
    },
  },

  {
    name: "MembershipEdit",
    path: "/memberships/edit/:data",
    component: MembershipsForm,
    meta: {
      item: "memberships",
      module: Modules.memberships.management,
      title: "Memberships",
    },
  },

  {
    name: "MembershipView",
    path: "/memberships/view/:data",
    component: MembershipView,
    meta: {
      item: "memberships",
      module: Modules.memberships.management,
      title: "Memberships",
    },
  },
  {
    name: "MembershipLogs",
    path: "/memberships/logs",
    component: MembershipLogs,
    meta: {
      item: "memberships",
      module: Modules.memberships.management,
      title: "Memberships",
    },
  },
  {
    name: "MembershipConfiguration",
    path: "/memberships/configurations",
    component: MembershipConfiguration,
    meta: {
      item: "memberships",
      module: Modules.memberships.management,
      title: "Memberships",
    },
  },

  //403
  {
    name: "Forbidden",
    path: "/forbidden",
    component: Forbidden,
    meta: {
      item: null,
      module: {
        id: null,
      },
      title: "Permission Denied",
    },
  },
  // Marketing
  {
    name: "Marketing",
    path: "/marketing",
    component: Marketing,
    meta: {
      item: "marketing",
      module: Modules.marketing.dashboard,
      title: "Marketing",
    },
  },

  {
    name: "Dashboard",
    path: "/dashboard",
    component: Dashboard,
    meta: {
      item: "dashboard",
      module: Modules.general.dashboard,
      title: "Dashboard",
    },
  },
  {
    name: "KioskDashboard",
    path: "/kiosk",
    component: KioskDashboard,
    meta: {
      item: "kiosk",
      module: Modules.kiosk.facility,
      title: "KIOSK",
    },
  },
  {
    name: "BookingForm",
    path: "/kiosk/booking/",
    component: BookingForm,
    meta: {
      item: "kiosk",
      module: Modules.kiosk.facility,
      title: "KIOSK",
    },
  },

  {
    name: "SocialPosts",
    path: "/marketing/social/:type",
    component: SocialPosts,
    meta: {
      item: "marketing",
      module: Modules.marketing.dashboard,
      title: "Marketing",
    },
  },

  {
    name: "EmailTemplateConfiguration",
    path: "/marketing/email-template-configuration/:slug/:id?",
    component: ConfigureEmailTemplate,
    meta: {
      item: "marketing",
      module: Modules.marketing.dashboard,
      title: "Marketing",
    },
  },
  {
    name: "MailSMS",
    path: "/marketing/message/:type",
    component: MailSMSDetails,
    meta: {
      item: "marketing",
      module: Modules.marketing.dashboard,
      title: "Marketing",
    },
  },

  {
    name: "AddSMSEmailMarketing",
    path: "/marketing/message/form/:type",
    component: AddSMSEmailMarketing,
    meta: {
      item: "marketing",
      module: Modules.marketing.dashboard,
      title: "Marketing",
    },
  },

  {
    name: "EditSMSEmailMarketing",
    path: "/marketing/message/form/:type/:id",
    component: AddSMSEmailMarketing,
    meta: {
      item: "marketing",
      module: Modules.marketing.dashboard,
      title: "Marketing",
    },
  },

  {
    name: "Newsletter",
    path: "/marketing/newsletter",
    component: Newsletter,
    meta: {
      item: "marketing",
      module: Modules.marketing.dashboard,
      title: "Marketing",
    },
  },
  {
    name: "OrderConfirmationMail",
    path: "/marketing/order-confirmation-configuration",
    component: OrderConfirmationMail,
    meta: {
      item: "marketing",
      module: Modules.marketing.dashboard,
      title: "Marketing",
    },
  },
  {
    name: "OrderCancelMail",
    path: "/marketing/order-cancel-configuration",
    component: OrderCancelMail,
    meta: {
      item: "marketing",
      module: Modules.marketing.dashboard,
      title: "Marketing",
    },
  },
  {
    name: "OrderChangeMail",
    path: "/marketing/order-change-configuration",
    component: OrderChangeMail,
    meta: {
      item: "marketing",
      module: Modules.marketing.dashboard,
      title: "Marketing",
    },
  },
  {
    name: "WelcomeMail",
    path: "/marketing/welcome-mail-configuration",
    component: WelcomeMail,
    meta: {
      item: "marketing",
      module: Modules.marketing.dashboard,
      title: "Marketing",
    },
  },
  {
    name: "NewMemberMail",
    path: "/marketing/new-member-configuration",
    component: NewMemberMail,
    meta: {
      item: "marketing",
      module: Modules.marketing.dashboard,
      title: "Marketing",
    },
  },
  {
    name: "RenewMemberMail",
    path: "/marketing/renew-member-configuration",
    component: RenewMemberMail,
    meta: {
      item: "marketing",
      module: Modules.marketing.dashboard,
      title: "Marketing",
    },
  },
  {
    name: "surveyEmailTemplate",
    path: "/marketing/survey-mail-configuration",
    component: SurveyMail,
    meta: {
      item: "marketing",
      module: Modules.marketing.dashboard,
      title: "Marketing",
    },
  },
  {
    name: "eInvoiceEmailTemplate",
    path: "/marketing/e_invoice-mail-configuration",
    component: EInvoice,
    meta: {
      item: "marketing",
      module: Modules.marketing.dashboard,
      title: "Marketing",
    },
  },
  {
    name: "approvalEmailTemplate",
    path: "/marketing/approval-mail-configuration",
    component: ApprovalMail,
    meta: {
      item: "marketing",
      module: Modules.marketing.dashboard,
      title: "Marketing",
    },
  },
  {
    name: "reminderEmailTemplate",
    path: "/marketing/reminder-mail-configuration",
    component: ReminderMail,
    meta: {
      item: "marketing",
      module: Modules.marketing.dashboard,
      title: "Marketing",
    },
  },
  {
    name: "reservationMailTemplate",
    path: "/marketing/reservation-mail-configuration",
    component: OrderReservationMail,
    meta: {
      item: "marketing",
      module: Modules.marketing.dashboard,
      title: "Marketing",
    },
  },
  {
    name: "refundMailTemplate",
    path: "/marketing/refund-mail-configuration",
    component: OrderRefundMail,
    meta: {
      item: "marketing",
      module: Modules.marketing.dashboard,
      title: "Marketing",
    },
  },
  {
    name: "membershipMailTemplate",
    path: "/marketing/membership-purchase-mail-configuration",
    component: MembershipPurchaseMail,
    meta: {
      item: "marketing",
      module: Modules.marketing.dashboard,
      title: "Marketing",
    },
  },

  {
    name: "promoMailTemplate",
    path: "/marketing/promo-mail-configuration",
    component: PromoMail,
    meta: {
      item: "marketing",
      module: Modules.marketing.dashboard,
      title: "Marketing",
    },
  },
  {
    name: "Sales",
    path: "/sales",
    component: Sales,
    meta: {
      item: "sales",
      module: Modules.sales.graph,
      title: "Sales",
    },
  },
  {
    name: "OnlineWallet",
    path: "/online-wallet",
    component: OnlineWallet,
    meta: {
      item: "online-wallet",
      module: Modules.sales.graph,
      title: "Online Wallet",
    },
  },
  {
    name: "KrewsWallet",
    path: "/krews-wallet",
    component: KrewsWallet,
    meta: {
      item: "krews-wallet",
      module: Modules.sales.graph,
      title: "Krews Wallet",
    },
  },
  {
    name: "Logs",
    path: "/logs",
    component: Logs,
    meta: {
      item: "sales",
      module: Modules.sales.logs,
      title: "Sales Logs",
    },
  },
  {
    name: "Invoices",
    path: "/invoices",
    component: Invoices,
    meta: {
      item: "sales",
      module: Modules.sales.logs,
      title: "Invoice Logs",
    },
  },
  {
    name: "Credits",
    path: "/credits",
    component: Invoices,
    // component: Logs,
    meta: {
      item: "sales",
      module: Modules.sales.credits,
      title: "Credit Sales Logs",
    },
  },
  {
    name: "Refunds",
    path: "/refunds",
    component: Invoices,
    meta: {
      item: "sales",
      module: Modules.sales.refunds,
      title: "Refund Sales Logs",
    },
  },
  {
    name: "Cancellations",
    path: "/cancellations",
    component: Invoices,
    meta: {
      item: "sales",
      module: Modules.sales.void,
      title: "Cancellation Sales Logs",
    },
  },

  // Reports
  {
    name: "Reports",
    path: "/reports",
    component: Reports,
    meta: {
      item: "reports",
      module: Modules.reports.dashboard,
      title: "Reports",
    },
  },
  {
    name: "VenueServiceReport",
    path: "/reports/venue-service-report",
    component: VenueServiceReport,
    meta: {
      item: "reports",
      module: Modules.reports.service,
      title: "Reports",
    },
  },
  {
    name: "ListBillReport",
    path: "/reports/bill-report",
    component: ListBillReport,
    meta: {
      item: "reports",
      module: Modules.reports.bills,
      title: "Reports",
    },
  },
  {
    name: "CashierReport",
    path: "/reports/cashier-report",
    component: CashierReport,
    meta: {
      item: "reports",
      module: Modules.reports.cashier,
      title: "Reports",
    },
  },
  {
    name: "DiscountReport",
    path: "/reports/discount-report",
    component: DiscountReport,
    meta: {
      item: "reports",
      module: Modules.reports.discount,
      title: "Reports",
    },
  },
  {
    name: "PaymentTypeSalesReport",
    path: "/reports/payment-sales-report",
    component: PaymentTypeSalesReport,
    meta: {
      item: "reports",
      module: Modules.reports.payment,
      title: "Reports",
    },
  },
  {
    name: "OnlineSalesSettlementReport",
    path: "/reports/online-sales-settlement-report",
    component: OnlineSalesSettlementReport,
    meta: {
      item: "reports",
      module: Modules.reports.payment,
      title: "Reports",
    },
  },
  {
    name: "WebsiteOnlineReport",
    path: "/reports/website-online-report",
    component: WebsiteOnlineReport,
    meta: {
      item: "reports",
      module: Modules.reports.website_sales,
      title: "Reports",
    },
  },
  {
    name: "PendingOrderReport",
    path: "/reports/pending-order-report",
    component: PendingOrderReport,
    meta: {
      item: "reports",
      module: Modules.reports.payment,
      title: "Reports",
    },
  },
  {
    name: "FacilityUsageReport",
    path: "/reports/facility-usage-report",
    component: FacilityUsageReport,
    meta: {
      item: "reports",
      module: Modules.reports.facility,
      title: "Reports",
    },
  },
  {
    name: "AttendanceReport",
    path: "/reports/attendance-report",
    component: AttendanceReport,
    meta: {
      item: "reports",
      module: Modules.reports.attendance,
      title: "Reports",
    },
  },
  {
    name: "TrainerPerformanceReport",
    path: "/reports/trainer-performance-report",
    component: TrainerPerformanceReport,
    meta: {
      item: "reports",
      module: Modules.reports.trainer,
      title: "Reports",
    },
  },
  {
    name: "OpenInvoiceReport",
    path: "/reports/open-invoice-report",
    component: OpenInvoiceReport,
    meta: {
      item: "reports",
      module: Modules.reports.invoice,
      title: "Reports",
    },
  },
  {
    name: "CashierAuditReport",
    path: "/reports/cashier-audit-report",
    component: CashierAuditReport,
    meta: {
      item: "reports",
      module: Modules.reports.cashier_audit,
      title: "Reports",
    },
  },
  {
    name: "ProgramReport",
    path: "/reports/program-report",
    component: ProgramReport,
    meta: {
      item: "reports",
      module: Modules.reports.programs,
      title: "Reports",
    },
  },
  {
    name: "ExpiringPackageReport",
    path: "/reports/expiring-package-report",
    component: ExpiringPackageReport,
    meta: {
      item: "reports",
      module: Modules.reports.expiring_packages,
      title: "Reports",
    },
  },
  {
    name: "ProductSalesReport",
    path: "/reports/product-sales-report",
    component: ProductSalesReport,
    meta: {
      item: "reports",
      module: Modules.reports.product,
      title: "Reports",
    },
  },
  {
    name: "CustomerDemographicReport",
    path: "/reports/customer-demographic-report",
    component: CustomerDemographicReport,
    meta: {
      item: "reports",
      module: Modules.reports.customer_demographic,
      title: "Reports",
    },
  },
  {
    name: "VisitationReport",
    path: "/reports/visitation-report",
    component: VisitationReport,
    meta: {
      item: "reports",
      module: Modules.reports.visitation,
      title: "Reports",
    },
  },
  {
    name: "B2BReport",
    path: "/reports/b2b-report",
    component: B2BReport,
    meta: {
      item: "reports",
      module: Modules.reports.b2b,
      title: "Reports",
    },
  },
  {
    name: "anonymous-visitation",
    path: "/reports/anonymous-visitation",
    component: AnonymousVisitationReport,
    meta: {
      item: "reports",
      module: Modules.reports.anonymous_visitation,
      title: "Reports",
    },
  },
  {
    name: "MembershipSalesReport",
    path: "/reports/membership-sales-report",
    component: MembershipSalesReport,
    meta: {
      item: "reports",
      module: Modules.reports.membership_sales,
      title: "Reports",
    },
  },
  {
    name: "VisitationCountReport",
    path: "/reports/visitation-count-report",
    component: VisitationCountReport,
    meta: {
      item: "reports",
      module: Modules.reports.visitation_analysis,
      title: "Reports",
    },
  },
  {
    name: "UserAuditReport",
    path: "/reports/user-audit-report",
    component: UserAuditReport,
    meta: {
      item: "reports",
      module: Modules.reports.user_audit,
      title: "Reports",
    },
  },
  {
    name: "SystemLogReport",
    path: "/reports/system-log-report",
    component: SystemLogReport,
    meta: {
      item: "reports",
      module: Modules.reports.system_log,
      title: "Reports",
    },
  },
  {
    name: "SalesLogReport",
    path: "/reports/sales-log-report",
    component: SalesLogReport,
    meta: {
      item: "reports",
      module: Modules.reports.sales_log,
      title: "Reports",
    },
  },
  {
    name: "MembershipInstallmentReport",
    path: "/reports/membership-installment-report",
    component: MembershipInstallmentReport,
    meta: {
      item: "reports",
      module: Modules.reports.membership_installment,
      title: "Reports",
    },
  },
  {
    name: "CustomerVisitationReport",
    path: "/reports/customer-visitation-report",
    component: CustomerVisitationReport,
    meta: {
      item: "reports",
      module: Modules.reports.customer_visitation,
      title: "Reports",
    },
  },

  {
    name: "OccupancyRateReport",
    path: "/reports/occupancy-rate-report",
    component: OccupancyRateReport,
    meta: {
      item: "reports",
      module: Modules.reports.occupancy_rate,
      title: "Reports",
    },
  },

  {
    name: "Workshops",
    path: "/workshops",
    component: Workshops,
    meta: {
      item: "academy",
      module: Modules.workshops.management,
      title: "Academy",
    },
  },
  {
    name: "WorkshopSales",
    path: "/workshop-sales",
    component: WorkshopSales,
    meta: {
      item: "academy",
      module: Modules.workshops.management,
      title: "Academy",
    },
  },
  {
    name: "WorkshopAdd",
    path: "/workshops/add",
    component: WorkshopForm,
    meta: {
      item: "academy",
      module: Modules.workshops.management,
      title: "Add Academy",
    },
  },
  {
    name: "WorkshopEdit",
    path: "/workshops/edit/:data",
    component: WorkshopForm,
    meta: {
      item: "academy",
      module: Modules.workshops.management,
      title: "Edit Academy",
    },
  },
  {
    name: "WorkshopView",
    path: "/workshops/details/:data",
    component: ViewWorkshop,
    meta: {
      item: "academy",
      module: Modules.workshops.management,
      title: "Academy",
    },
  },
  {
    name: "WorkshopConfiguration",
    path: "/workshops/configurations",
    component: WorkshopConfiguration,
    meta: {
      item: "academy",
      module: Modules.workshops.management,
      title: "Academy",
    },
  },

  {
    name: "B2CGeneralConfig",
    path: "/b2c/general",
    component: B2CGeneralConfig,
    meta: {
      item: "b2cconfig",
      module: Modules.b2cconfig.general,
      title: "B2C Config",
    },
  },

  {
    name: "B2CPagesConfig",
    path: "/b2c/pages",
    component: B2CPagesConfig,
    meta: {
      item: "b2cconfig",
      module: Modules.b2cconfig.general,
      title: "B2C Config",
    },
  },

  {
    name: "B2CSportsConfig",
    path: "/b2c/sports",
    component: B2CSportsConfig,
    meta: {
      item: "b2cconfig",
      module: Modules.b2cconfig.general,
      title: "B2C Config",
    },
  },
  {
    name: "B2CBookingsConfig",
    path: "/b2c/bookings-config",
    component: B2CBookingsConfig,
    meta: {
      item: "b2cconfig",
      module: Modules.b2cconfig.general,
      title: "B2C Config",
    },
  },
  {
    name: "QubeAppConfig",
    path: "/b2c/qube-app-config",
    component: QubeAppConfig,
    meta: {
      item: "b2cconfig",
      module: Modules.b2cconfig.general,
      title: "B2C Config",
    },
  },
  {
    name: "KioskConfig",
    path: "/b2c/kiosk-config",
    component: KioskConfig,
    meta: {
      item: "b2cconfig",
      module: Modules.b2cconfig.general,
      title: "B2C Kiosk Config",
    },
  },
  {
    name: "B2CAdditionalPage",
    path: "/b2c/pages/additional",
    component: B2CAdditionalPage,
    meta: {
      item: "b2cconfig",
      module: Modules.b2cconfig.general,
      title: "B2C Additional Page",
    },
  },
  {
    name: "B2CMenu",
    path: "/b2c/menu",
    component: B2CMenu,
    meta: {
      item: "b2cconfig",
      module: Modules.b2cconfig.general,
      title: "B2C Menu",
    },
  },
  {
    name: "B2CBlogs",
    path: "/b2c/blogs",
    component: B2CBlogs,
    meta: {
      item: "b2cconfig",
      module: Modules.b2cconfig.general,
      title: "B2C Blogs",
    },
  },
  {
    name: "B2CBlog",
    path: "/b2c/blog/:id?",
    component: BlogForm,
    meta: {
      item: "b2cconfig",
      module: Modules.b2cconfig.general,
      title: "B2C Blogs",
    },
  },
  {
    name: "CardReader",
    path: "/demo/card-reader",
    component: CardReader,
    meta: {
      item: "b2cconfig",
      module: Modules.b2cconfig.general,
      title: "Card Reader",
    },
  },

  {
    name: "B2bDashboard",
    path: "/b2b",
    component: B2bDashboard,
    meta: {
      item: "b2b",
      module: Modules.general.dashboard,
      title: "B2B Dashboard",
    },
  },

  {
    name: "B2bPartners",
    path: "/b2b/partners",
    component: B2bPartners,
    meta: {
      item: "b2b",
      module: Modules.general.dashboard,
      title: "B2B Partners",
    },
  },
  {
    name: "B2bProducts",
    path: "/b2b/products/",
    component: B2bProducts,
    meta: {
      item: "b2b",
      module: Modules.general.dashboard,
      title: "B2B Products",
    },
  },

  {
    name: "B2bPartnersView",
    path: "/b2b/partners/view/:data",
    component: B2bPartnersView,
    meta: {
      item: "b2b",
      module: Modules.general.dashboard,
      title: "B2B Partners View",
    },
  },
  {
    name: "B2bPartnerProducts",
    path: "/b2b/partner/products/:data",
    component: B2bPartnerProducts,
    meta: {
      item: "b2b",
      module: Modules.general.dashboard,
      title: "B2B Partner Products",
    },
  },

  {
    name: "B2bConfiguration",
    path: "/b2b/configurations",
    component: B2bConfiguration,
    meta: {
      item: "b2b",
      module: Modules.general.dashboard,
      title: "B2b Configuration",
    },
  },
  {
    name: "PosView",
    path: "/pos",
    component: PosView,
    meta: {
      item: "pos",
      module: Modules.pos.dashboard,
      title: "POS View",
    },
  },
  {
    name: "ProductManagement",
    path: "/pos/product-management",
    component: ProductManagement,
    meta: {
      item: "pos",
      module: Modules.pos.dashboard,
      title: "POS Product Management",
    },
  },
  {
    name: "AttributeManagement",
    path: "/pos/attribute-management",
    component: ProductManagement,
    meta: {
      item: "pos",
      module: Modules.pos.dashboard,
      title: "POS Product Management",
    },
  },
  {
    name: "CategoryManagement",
    path: "/pos/category-management",
    component: ProductManagement,
    meta: {
      item: "pos",
      module: Modules.pos.dashboard,
      title: "POS Category Management",
    },
  },
  {
    name: "InventoryManagement",
    path: "/pos/inventory-management",
    component: ProductManagement,
    meta: {
      item: "pos",
      module: Modules.pos.dashboard,
      title: "POS Inventory Management",
    },
  },
  {
    name: "AnnouncementDetails",
    path: "/marketing/announcement-details",
    component: AnnouncementDetails,
    meta: {
      item: "marketing",
      module: Modules.marketing.dashboard,
      title: "Marketing",
    },
  },
  {
    name: "WebsiteNotification",
    path: "/marketing/website-notifications",
    component: WebsiteNotification,
    meta: {
      item: "marketing",
      module: Modules.marketing.dashboard,
      title: "Marketing",
    },
  },

  {
    name: "AddAnnouncement",
    path: "/marketing/announcement/form/:type",
    component: AddAnnouncement,
    meta: {
      item: "marketing",
      module: Modules.marketing.dashboard,
      title: "Marketing",
    },
  },
  {
    name: "AddWebsiteNotification",
    path: "/marketing/website-notification/form/:id?",
    component: WebsiteNotificationForm,
    meta: {
      item: "marketing",
      module: Modules.marketing.dashboard,
      title: "Marketing",
    },
  },
  {
    name: "EditAnnouncement",
    path: "/marketing/announcement/form/:type/:id",
    component: AddAnnouncement,
    meta: {
      item: "marketing",
      module: Modules.marketing.dashboard,
      title: "Marketing",
    },
  },
  {
    name: "Outlets",
    path: "/outlets",
    component: Outlets,
    meta: {
      item: "outlets",
      module: Modules.outlet.dashboard,
      title: "Outlets",
    },
  },
  {
    name: "OutletView",
    path: "/outlets/view/:data",
    component: OutletView,
    meta: {
      item: "outlets",
      module: Modules.outlet.dashboard,
      title: "Outlets",
    },
  },
  {
    name: "OutletsProductManagement",
    path: "/outlets/product-management/:data",
    component: OutletProductManagement,
    meta: {
      item: "outlets",
      module: Modules.outlet.dashboard,
      title: "Outlet Product Management",
    },
  },
  {
    name: "OutletsCategoryManagement",
    path: "/outlets/category-management/:data",
    component: OutletProductManagement,
    meta: {
      item: "outlets",
      module: Modules.outlet.dashboard,
      title: "Outlet Category Management",
    },
  },
  {
    name: "OutletsAttributeManagement",
    path: "/outlets/attribute-management/:data",
    component: OutletProductManagement,
    meta: {
      item: "outlets",
      module: Modules.outlet.dashboard,
      title: "Outlet Attribute Management",
    },
  },

  {
    name: "CustomerSupport",
    path: "/venue/support",
    component: CustomerSupport,
    meta: {
      item: "customer-support",
      module: Modules.general.profile,
      title: "Customer Support",
    },
  },
  {
    name: "CreateSupportTicket",
    path: "/venue/support/create",
    component: CreateSupportTicket,
    meta: {
      item: "customer-support",
      module: Modules.general.profile,
      title: "Customer Support",
    },
  },
  {
    name: "CustomerSupportDetails",
    path: "/venue/support/:id",
    component: CustomerSupportDetails,
    meta: {
      item: "customer-support",
      module: Modules.general.profile,
      title: "Customer Support Details",
    },
  },
  {
    name: "SalesTeams",
    path: "/sales-teams",
    component: SalesTeams,
    meta: {
      item: "sales-teams",
      module: Modules.salesTeam.dashboard,
      title: "SalesTeams",
    },
  },
  {
    name: "SalesTeamView",
    path: "/sales-teams/logs/:data",
    component: SalesTeamView,
    meta: {
      item: "sales-teams",
      module: Modules.salesTeam.dashboard,
      title: "Sales Logs",
    },
  },
  {
    name: "leadTracking",
    path: "/lead-tracking",
    component: LeadsDashboard,
    meta: {
      item: "lead-tracking",
      module: Modules.leadTracking.management,
      title: "Lead Tracking",
    },
  },

  {
    name: "leadTrackingConfiguration",
    path: "/lead-tracking/configuration",
    component: LeadsFormConfiguration,
    meta: {
      item: "lead-tracking",
      module: Modules.leadTracking.management,
      title: "Lead Tracking",
    },
  },

  {
    name: "QPointsDashboard",
    path: "/qpoints/dashboard",
    component: QPointsDashboard,
    meta: {
      item: "QPointsDashboard",
      module: Modules.qpoints.management,
      title: "QPoints Dashboard",
    },
  },
  {
    name: "QPointsLogs",
    path: "/qpoints/logs",
    component: QPointsLogs,
    meta: {
      item: "QPointsLogs",
      module: Modules.qpoints.management,
      title: "QPoints Logs",
    },
  },
  {
    name: "QPointsConfig",
    path: "/qpoints/config",
    component: QPointsConfig,
    meta: {
      item: "QPointsConfig",
      module: Modules.qpoints.management,
      title: "QPoints Config",
    },
  },
  {
    name: "ShopFacility",
    path: "/shop/facility",
    component: ShopFacility,
    meta: {
      item: "Shop",
      module: Modules.shop.dashboard,
      title: "Shop",
    },
  },
  {
    name: "ShopEvents",
    path: "/shop/events",
    component: ShopEvents,
    meta: {
      item: "Shop",
      module: Modules.shop.dashboard,
      title: "Shop",
    },
  },
  {
    name: "ShopMemberships",
    path: "/shop/memberships",
    component: ShopMemberships,
    meta: {
      item: "Shop",
      module: Modules.shop.dashboard,
      title: "Shop",
    },
  },
  {
    name: "ShopTrainers",
    path: "/shop/trainers",
    component: ShopTrainers,
    meta: {
      item: "Shop",
      module: Modules.shop.dashboard,
      title: "Shop",
    },
  },
  {
    name: "ShopAcademies",
    path: "/shop/academy",
    component: ShopAcademies,
    meta: {
      item: "Shop",
      module: Modules.shop.dashboard,
      title: "Shop",
    },
  },
  {
    name: "ShopRetail",
    path: "/shop/retail",
    component: ShopRetail,
    meta: {
      item: "Shop",
      module: Modules.shop.dashboard,
      title: "Shop",
    },
  },
  {
    name: "ShopOffers",
    path: "/shop/offers",
    component: ShopOffers,
    meta: {
      item: "Shop",
      module: Modules.shop.dashboard,
      title: "Shop",
    },
  },
  {
    name: "ShopCart",
    path: "/shop/cart",
    component: ShopCart,
    meta: {
      item: "Shop",
      module: Modules.shop.dashboard,
      title: "Shop",
    },
  },


  {
    name: "Offers",
    path: "/offers",
    component: Offers,
    meta: {
      item: "offers",
      module: Modules.offers.management,
      title: "Offers",
    },
  },

  {
    name: "Suggestions",
    path: "/suggestions",
    component: Suggestions,
    meta: {
      item: "offers",
      module: Modules.suggestions.management,
      title: "Suggestions",
    },
  },

  {
    name: "Cross-sale",
    path: "/cross-sale",
    component: CrossSale,
    meta: {
      item: "offers",
      module: Modules.suggestions.management,
      title: "Cross-Sale",
    },
  },

  {
    name: "Pricing",
    path: "/master-pricing",
    component: MasterPricing,
    meta: {
      item: "pricing",
      module: Modules.masterPricing.management,
      title: "Master Pricing",
    },
  },
  {
    name: "MediaLibrary",
    path: "/media-library",
    component: MediaLibrary,
    meta: {
      item: "mediaLibrary",
      module: Modules.mediaLibrary.management,
      title: "Media Library",
    },
  },


  //404
  {
    name: "NotFound",
    path: "*",
    component: NotFoundPage,
    meta: {
      item: null,
      module: {
        id: null,
      },
      title: "Not Found",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
