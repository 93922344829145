<template>
  <v-dialog v-model="MediaListingModal" scrollable width="600px" persistent>
    <v-card class="card-rounded-bottom" style="min-height: 200px;">
      <v-card-text class="border-bottom">
        <div class="row pt-1">
          <div class="col-md-12">
            <div class="d-flex justify-space-between align-center mt-2">
              <SvgIcon class="text-2xl font-semibold"
                       text="Choose File" style="color: black">
              </SvgIcon>
              <v-btn fab x-small class="shadow-0" @click="close">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </v-card-text>
      <div class="mx-6 my-4 border-bottom pb-2">
        <nav aria-label="breadcrumb" class="breadcrumb-container">
          <span class="mr-1" style="font-size: 14px">Current Path:</span>
          <ol class="breadcrumb">
            <li v-for="(crumb, index) in breadCrumbs" :key="index" class="breadcrumb-item">
                    <span
                        @click="navigateFolders(crumb.id)"
                        class="breadcrumb-link"
                    >
                      {{ crumb.name }}
                    </span>
              <span v-if="index < breadCrumbs.length - 1"> > </span>
            </li>
          </ol>
        </nav>
      </div>
      <div class="md-card-content overflow-y-auto pt-0" style="max-height: 500px;">
        <v-simple-table class="table">
          <template v-slot:default>
            <thead>
            <tr class="rounded-sm tr-rounded tr-neon opacity-70" v-show="false">
              <th class="text-left black-text">Choose folder</th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="tableRows.length < 1">
              <td colspan="5" class="text-center mt-4">
                <h3>No folders found</h3>
              </td>
            </tr>
            <tr class="text-left" v-for="(item,key) in tableRows" :key="key">
              <td @click="selectTarget(item)">
                <div class="d-flex align-center gap-x-2">
                  <component v-if="icons[item.extension]" :component="icons[item.extension]"
                             :is="icons[item.extension]"></component>
                  <DefaultIcon v-else></DefaultIcon>
                  <span class="breadcrumb-link">{{ item.name }}</span>
                </div>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import SvgIcon from "@/components/Image/SvgIcon.vue";
import FolderIcon from "@/assets/images/misc/Folder.svg";
import DefaultIcon from "@/assets/images/misc/file-icons/Default.svg";
import FolderFileIcon from "@/assets/images/misc/file-icons/Folder.svg";
import PdfIcon from "@/assets/images/misc/file-icons/PDF.svg";
import PptIcon from "@/assets/images/misc/file-icons/PPT.svg";
import CsvIcon from "@/assets/images/misc/file-icons/CSV.svg";
import XlsIcon from "@/assets/images/misc/file-icons/XLS.svg";
import XlsxIcon from "@/assets/images/misc/file-icons/XLSX.svg";
import DocIcon from "@/assets/images/misc/file-icons/DOC.svg";
import DocxIcon from "@/assets/images/misc/file-icons/DOCX.svg";
import JpgIcon from "@/assets/images/misc/file-icons/JPG.svg";
import JpegIcon from "@/assets/images/misc/file-icons/JPEG.svg";
import PngIcon from "@/assets/images/misc/file-icons/PNG.svg";
import GifIcon from "@/assets/images/misc/file-icons/GIF.svg";
import TxtIcon from "@/assets/images/misc/file-icons/TXT.svg";

export default {
  name: "MediaListingModal",
  components: {DefaultIcon, FolderIcon, SvgIcon},
  props: {
    MediaListingModal: {type: Boolean, default: false},
  },
  watch: {
    MediaListingModal(val) {
      if (val) {
        this.navigateFolders(null);
      } else {
        this.tableRows = [];
      }
    }
  },
  data() {
    return {
      icons: {
        'folder': FolderFileIcon,
        'pdf': PdfIcon,
        'ppt': PptIcon,
        'csv': CsvIcon,
        'xls': XlsIcon,
        'xlsx': XlsxIcon,
        'doc': DocIcon,
        'docx': DocxIcon,
        'jpg': JpgIcon,
        'jpeg': JpegIcon,
        'png': PngIcon,
        'gif': GifIcon,
        'txt': TxtIcon,
      },
      tableRows: [],
      targetFile: null,
      targetId: null,
      breadCrumbs: [],
    }
  },
  methods: {
    selectTarget(item) {
      if (item.type === 'folder') {
        this.navigateFolders(item.id);
        this.$forceUpdate();
      } else {
        this.targetFile = item;
        this.$emit("selectTarget", item);
      }
    },
    navigateFolders(id = null) {
      this.targetId = id;
      let url = `venues/media-assets/get/folder-files-inner?type=image`;
      if (id) {
        url = `venues/media-assets/get/folder-files-inner/${id}?type=image`;
      }
      this.showLoader('Loading data');
      this.$http.get(url).then((response) => {
        if (response.status == 200) {
          this.tableRows = response.data.data
          this.breadCrumbs = [
            {
              name: 'Library',
              id: null,
            },
          ];

          if (response.data.bread_crumbs && Array.isArray(response.data.bread_crumbs)) {
            response.data.bread_crumbs.forEach(item => {
              if (item.name && item.id !== undefined) {
                this.breadCrumbs.push({
                  name: item.name,
                  id: item.id,
                });
              }
            });
          }

        }
      }).catch((error) => {
        this.errorChecker(error);
      }).finally(() => {
        this.hideLoader();
      });
    },
    close() {
      this.$emit("close");
    },
  },
}
</script>

<style scoped>

.breadcrumb-container {
  display: flex;
  align-items: center;
  font-size: 16px;
  flex-wrap: wrap;
}

.breadcrumb {
  font-size: 14px;
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}

.breadcrumb-item {
  display: flex;
  align-items: center;
  margin-right: 5px;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  white-space: nowrap;
}

.breadcrumb-link {
  cursor: pointer;
  color: black;
}

.breadcrumb-link:hover {
  text-decoration: underline;
  color: #00b1af;
}

.breadcrumb-item span {
  margin-right: 5px;
}

</style>