<template>
  <v-dialog v-model="AddMediaModal" scrollable width="400px" persistent>
    <v-form ref="form" v-model="valid">
      <v-card class="card-rounded-bottom" style="min-height: 200px">
        <v-card-text class="border-bottom">
          <div class="row pt-1 border-bottom">
            <div class="col-md-12">
              <div class="d-flex justify-space-between align-center mt-2">
                <SvgIcon class="text-2xl font-semibold"
                         :text="!isFolder ? 'Upload File': 'Create Folder'" style="color: black">
                </SvgIcon>
                <v-btn fab x-small class="shadow-0" @click="close">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
          <v-row class="d-flex align-center justify-center">
            <v-col md="12">
              <label for="">{{ !isFolder ? 'File ': 'Folder '}}Name</label>
              <v-text-field
                  v-model="name"
                  outlined
                  required
                  :rules="[(v) => !!v || 'Name is required']"
                  class="q-text-field shadow-0"
                  hide-details="auto"
                  dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="!isFolder" class="d-flex align-center justify-center">
            <v-col md="12"  v-if="!isFolder">
              <label for="">Choose File</label>
              <v-file-input
                  v-if="!isFolder"
                  label=""
                  v-model="file"
                  prepend-inner-icon="mdi-paperclip"
                  prepend-icon
                  show-size
                  outlined
                  :rules="[(v) => !!v || 'File is required']"
                  background-color="#fff"
                  dense
                  hide-details="auto"
                  class="q-text-field shadow-0"
              >
              </v-file-input>
            </v-col>
          </v-row>

        </v-card-text>
        <v-card-actions class="card-footer">
          <div class="d-flex w-full justify-end align-center mt-0">
            <v-btn
                elevation="0"
                class="ma-2 white--text blue-color"
                @click="save"
            >Save
            </v-btn
            >
          </div>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import SvgIcon from "@/components/Image/SvgIcon.vue";

export default {
  name: "AddMediaModal",
  components: {SvgIcon},
  props: {
    AddMediaModal: {type: Boolean, default: false},
    isFolder: {type: Boolean, default: false},
    clearModal: {type: Boolean, default: false},
  },
  computed: {},
  mounted() {
  },
  data() {
    return {
      valid: false,
      file: null,
      name: null,
    }
  },
  watch: {
    clearModal() {
      this.file = null;
      this.name = null;
    }
  },
  methods: {
    close() {
      this.$refs.form.resetValidation();
      this.$emit("closeAddMediaModal");
    },
    save() {
      this.$emit("save", {name: this.name, file: this.file});
    }
  },
}
</script>


<style scoped>

</style>