import axios from "../../utils/request";
import moment from "moment";

const getFacilityScheduleState = () => {
  return {
    facilitySchedule: {},
    hover: {},
  };
};

const state = getFacilityScheduleState();

const getters = {
  getFacilitySchedule: (state) => state.facilitySchedule,
};

const actions = {
  loadFacilitySchedule(context, params) {
    return new Promise((resolve, reject) => {
      let url = `venue_service_id=${params.venue_service_id}&date=${params.date}`;
      if (params.game_formation_id.length) {
        url += params.game_formation_id
          .map((item, index) => `&game_formation_id[${index}]=${item}`)
          .join(",");
      }
      axios
        .get(`venues/facilities/bookings/schedule?${url}`)
        .then((response) => {
          if (response && response.status == 200 && response.data.status == true) {
            const data = response.data.data;
            const configuration = data.configuration
              ? data.configuration
              : {
                  per_capacity: 0,
                  time_increment: 60,
                };
            configuration.time_increment = configuration.time_increment
              ? configuration.time_increment
              : 60;
            configuration.date = params.date;
            configuration.backfill = params.backfill;
            data.configuration = configuration;
            helper.parseScheduleData(data.facilities, configuration).then((parseData) => {
                helper.arrangeScheduleBlocks(parseData, configuration).then((scheduleData) => {
                    context.commit("setFacilitySchedule", scheduleData);
                  }).catch((error) => console.log(error));
              }).catch((error) => console.log(error));
            resolve(response);
          }
          reject(response);
        })
        .catch((err) => {
          console.log("error", err.message);
          reject(err);
        });
    });
  },

  loadFacilityBasedSchedule(context, params) {
    return new Promise((resolve, reject) => {
      let url = `venue_service_id=${params.venue_service_id}&date=${params.date}&facility_id=${params.facility_id}`;


      url += '&weekdays='+params.weekDays.join(',');


      if (params.game_formation_id.length) {
        url += params.game_formation_id
            .map((item, index) => `&game_formation_id[${index}]=${item}`)
            .join(",");
      }
      axios
          .get(`venues/facilities/bookings/facility-schedule?${url}`)
          .then((response) => {
            if (response && response.status == 200 && response.data.status == true) {
              const data = response.data.data;
              const configuration = data.configuration
                  ? data.configuration
                  : {
                    per_capacity: 0,
                    time_increment: 60,
                  };
              configuration.time_increment = configuration.time_increment
                  ? configuration.time_increment
                  : 60;
              configuration.date = params.date;
              configuration.backfill = params.backfill;
              data.configuration = configuration;
              facilityBasedHelper.parseScheduleData(data.facilities, configuration).then((parseData) => {
                facilityBasedHelper.arrangeScheduleBlocks(parseData, configuration).then((scheduleData) => {
                  context.commit("setFacilitySchedule", scheduleData);
                }).catch((error) => console.log(error));
              }).catch((error) => console.log(error));
              resolve(response);
            }
            reject(response);
          })
          .catch((err) => {
            console.log("error", err.message);
            reject(err);
          });
    });
  },
  highlightTime({ commit }, payload) {
    commit("HIGHLIGHT", payload);
  },
  removeHighlightTime({ commit }, payload) {
    commit("REMOVE_HIGHLIGHT", payload);
  },
};

const helper = {
  parseScheduleData(data, configuration) {
    return new Promise((resolve, reject) => {
      if (data.length > 0) {
        const filter_date = configuration.filter_date;
        const fcRentalTimes = this.getFacilityRentalsStartEndTime(data);
        const facilities = data.map((facility) => {
          let facilityData = {
            id: facility.id,
            name: facility.name,
            game_formations: facility.game_formations,
          };
          facilityData.rentals = {};
          let rentals = [];
          facility.facility_rentals.forEach((item) => {
            rentals.push(item);
          });
          rentals.forEach((element) => {
            let index = helper.parseTimeData(element);
            facilityData.rentals[index.startIndex] = {
              startIndex: index.startIndex,
              endIndex: index.endIndex,
            };
          });
          let notAvailable = [];
          let events = [];
          let workshops = [];
          let maintenances = [];
          let cartItems = [];
          // let eventSchedules = [];
          facility.child_facilities.forEach((child) => {
            notAvailable.push(...child.bookings);
            // events.push(...child.events);
            events.push(...child.event_schedules);
            workshops.push(...child.workshop_program_schedules);
            workshops.push(...child.workshop_program_reschedules);
            maintenances.push(...child.maintenances);
          });
          facility.parent_facilities.forEach((parent) => {
            notAvailable.push(...parent.bookings);
            // events.push(...parent.events);
            events.push(...parent.event_schedules);
            workshops.push(...parent.workshop_program_schedules);
            workshops.push(...parent.workshop_program_reschedules);
            maintenances.push(...parent.maintenances);
          });
          // events.push(...facility.events);
          events.push(...facility.event_schedules);
          workshops.push(...facility.workshop_program_schedules);
          workshops.push(...facility.workshop_program_reschedules);
          maintenances.push(...facility.maintenances);
          cartItems.push(...facility.cart_items);

          facilityData.maintenances = {};
          maintenances.forEach((element) => {
            let index = helper.parseTimeData(element);
            facilityData.maintenances[index.startIndex] = {
              startIndex: index.startIndex,
              endIndex: index.endIndex,
              ...element,
            };
          });

          facilityData.workshops = {};
          workshops.forEach((element) => {
            let index = helper.parseTimeData(element);
            if (facilityData.workshops[index.startIndex]) {
              facilityData.workshops[index.startIndex].end_time =
                element.end_time;
              facilityData.workshops[index.startIndex].endIndex =
                index.endIndex;
              facilityData.workshops[index.startIndex].name +=
                "<br /> " + element.name;
            } else {
              facilityData.workshops[index.startIndex] = {
                startIndex: index.startIndex,
                endIndex: index.endIndex,
                ...element,
              };
            }
          });

          facilityData.events = {};
          events.forEach((element) => {
            let index = helper.parseTimeData(element);
            if (
              moment(configuration.date, "YYYY-MM-DD") <
              moment(element.end_date, "YYYY-MM-DD")
            ) {
              if (
                moment(configuration.date, "YYYY-MM-DD") >
                moment(element.start_date, "YYYY-MM-DD")
              ) {
                index = helper.parseTimeAllDay(element);
              } else {
                index = helper.parseTimeTillEndOfDay(element);
              }
            }
            if (
              moment(configuration.date, "YYYY-MM-DD") >
              moment(element.start_date, "YYYY-MM-DD")
            ) {
              if (
                moment(configuration.date, "YYYY-MM-DD") <
                moment(element.end_date, "YYYY-MM-DD")
              ) {
                index = helper.parseTimeAllDay(element);
              } else {
                index = helper.parseTimeFromStartOfDay(element);
              }
            }

            facilityData.events[index.startIndex] = {
              startIndex: index.startIndex,
              endIndex: index.endIndex,
              ...element,
            };
          });

          let frTime = fcRentalTimes[facility.id];
          let endTime = "11:59:59";
          facilityData.bookings = {};
          facility.bookings.forEach((element) => {
            let index = {};
            let isOverNight=false;
            if (facility.per_capacity === 1) {
              index = helper.parseTimeData(element);
              let timeDifference = index.endIndex - index.startIndex;
              if (timeDifference == 89) {
                timeDifference = 90;
              }
              /** calculate parent total */
              let parentOrderTotal = 0;
              if (element.parent_orders) {
                element.parent_orders.map((item) => {
                  parentOrderTotal += item.total;
                });
              }
              if (configuration.time_increment < timeDifference) {
                let startIndex = index.startIndex;
                let endIndex = index.endIndex;
                for (let i = configuration.time_increment;i <= timeDifference; i += configuration.time_increment) {
                  endIndex = startIndex + configuration.time_increment;
                  if (facilityData.bookings[startIndex] == null) {
                    facilityData.bookings[startIndex] = {
                      bookings: element.attendance ? element.attendance : 0,
                      startIndex: startIndex,
                      endIndex: endIndex,
                      participants: [],
                      duplicate_id: element.facility_booking_duplicate_id,
                      is_booking_approved: element.is_booking_approved,
                      is_over_night:isOverNight,
                      colourStatus:
                        element.status_id == 5
                          ? "unpaid"
                          : element.is_trainer == 1
                          ? "trainer"
                          : "paid",
                      ...element,
                    };
                  } else {
                    facilityData.bookings[startIndex].bookings += element.attendance;
                    facilityData.bookings[startIndex].colourStatus = element.status_id == 5? "unpaid" : facilityData.bookings[startIndex].colourStatus;
                  }
                  if (element.group_customers) {
                    let isIPaid = false;
                    if (element.attendance == element.group_customers.length) {
                      element.group_customers.map((item) => {
                        item.facility_id = element.facility_id;
                        item.status_id = element.status_id;
                        item.order_total = element.total + parentOrderTotal;
                        item.is_trainer = element.is_trainer == 1 ? 1 : 0;
                        if ( item.customer_id == element.customer_id && !isIPaid) {
                          isIPaid = true;
                          item.i_paid = 1;
                        } else {
                          item.i_paid = 0;
                        }
                        facilityData.bookings[startIndex].participants.push(item);
                      });
                    } else if ( element.attendance > element.group_customers.length) {
                      let totalCustomersCount = element.group_customers.length;
                      let remainingCustomerCount = element.attendance - totalCustomersCount;
                      element.group_customers.map((item) => {
                        item.facility_id = element.facility_id;
                        item.status_id = element.status_id;
                        item.order_total = element.total + parentOrderTotal;
                        item.is_trainer = element.is_trainer == 1 ? 1 : 0;
                        if (item.customer_id == element.customer_id && !isIPaid) {
                          isIPaid = true;
                          item.i_paid = 1;
                        } else {
                          item.i_paid = 0;
                        }
                        facilityData.bookings[startIndex].participants.push(item);
                      });

                      for (let step = 0; step < remainingCustomerCount; step++) {
                        let copy = { ...element.group_customers[0],};
                        copy.facility_id = element.facility_id;
                        copy.status_id = element.status_id;
                        copy.order_total = element.total + parentOrderTotal;
                        copy.is_trainer = element.is_trainer == 1 ? 1 : 0;
                        copy.products = element.products;
                        copy.fb_duplicate_id = element.facility_booking_duplicate_id;
                        copy.i_paid = 0;
                        facilityData.bookings[startIndex].participants.push(copy);
                      }
                    } else {
                      element.group_customers.map((item) => {
                        item.facility_id = element.facility_id;
                        item.status_id = element.status_id;
                        item.order_total = element.total + parentOrderTotal;
                        item.is_trainer = element.is_trainer == 1 ? 1 : 0;
                        item.products = element.products;
                        item.fb_duplicate_id = element.facility_booking_duplicate_id;
                        if (item.customer_id == element.customer_id && !isIPaid) {
                          isIPaid = true;
                          item.i_paid = 1;
                        } else {
                          item.i_paid = 0;
                        }
                        facilityData.bookings[startIndex].participants.push(item);
                      });
                    }
                  }
                  startIndex = endIndex;
                }
              } else {
                if (facilityData.bookings[index.startIndex] == null) {
                  facilityData.bookings[index.startIndex] = {
                    bookings: element.attendance ? element.attendance : 0,
                    startIndex: index.startIndex,
                    endIndex: index.endIndex,
                    participants: [],
                    is_booking_approved: element.is_booking_approved,
                    colourStatus: element.status_id == 5? "unpaid": element.is_trainer == 1? "trainer": "paid",
                    ...element,
                  };
                } else {
                  facilityData.bookings[index.startIndex].bookings += element.attendance;
                }
                if (element.group_customers) {
                  let isIPaid = false;
                  if (element.attendance == element.group_customers.length) {
                    element.group_customers.map((item) => {
                      item.facility_id = element.facility_id;
                      item.status_id = element.status_id;
                      item.order_total = element.total + parentOrderTotal;
                      item.is_trainer = element.is_trainer == 1 ? 1 : 0;
                      item.products = element.products;
                      item.fb_duplicate_id = element.facility_booking_duplicate_id;
                      if (item.customer_id == element.customer_id && !isIPaid) {
                        isIPaid = true;
                        item.i_paid = 1;
                      } else {
                        item.i_paid = 0;
                      }
                      facilityData.bookings[index.startIndex].participants.push(item);
                    });
                  } else if (element.attendance > element.group_customers.length) {
                    let totalCustomersCount = element.group_customers.length;
                    let remainingCustomerCount = element.attendance - totalCustomersCount;
                    element.group_customers.map((item) => {
                      item.facility_id = element.facility_id;
                      item.status_id = element.status_id;
                      item.order_total = element.total + parentOrderTotal;
                      item.is_trainer = element.is_trainer == 1 ? 1 : 0;
                      item.products = element.products;
                      item.fb_duplicate_id = element.facility_booking_duplicate_id;
                      if (item.customer_id == element.customer_id && !isIPaid) {
                        isIPaid = true;
                        item.i_paid = 1;
                      } else {
                        item.i_paid = 0;
                      }
                      facilityData.bookings[index.startIndex].participants.push(item);
                    });

                    for (let step = 0; step < remainingCustomerCount; step++) {
                      let copy = { ...element.group_customers[0],};
                      copy.facility_id = element.facility_id;
                      copy.status_id = element.status_id;
                      copy.order_total = element.total + parentOrderTotal;
                      copy.is_trainer = element.is_trainer == 1 ? 1 : 0;
                      copy.is_extra = 1;
                      copy.i_paid = 0;
                      copy.products = element.products;
                      copy.fb_duplicate_id =
                        element.facility_booking_duplicate_id;
                      facilityData.bookings[index.startIndex].participants.push(
                        copy
                      );
                    }
                  } else {
                    element.group_customers.map((item) => {
                      item.facility_id = element.facility_id;
                      item.status_id = element.status_id;
                      item.order_total = element.total + parentOrderTotal;
                      item.is_trainer = element.is_trainer == 1 ? 1 : 0;
                      if (item.customer_id == element.customer_id && !isIPaid) {
                        isIPaid = true;
                        item.i_paid = 1;
                      } else {
                        item.i_paid = 0;
                      }
                      item.products = element.products;
                      item.fb_duplicate_id = element.facility_booking_duplicate_id;
                      facilityData.bookings[index.startIndex].participants.push(item);
                    });
                  }
                }
              }
            } else {
              if(filter_date == element.date && element.booking_end_date && (filter_date != element.booking_end_date)){
                endTime = element.booking_end_date != element.date?frTime.fr_end_time:element.end_time;
                index = helper.parseOverNightTimeData(element.start_time,endTime);
              }else if(element.booking_end_date && filter_date == element.booking_end_date && (filter_date != element.date)){
                index = helper.parseOverNightTimeData(frTime.fr_start_time,element.end_time);
                isOverNight = true;
              }else{
                index = helper.parseTimeData(element);
              }
              facilityData.bookings[index.startIndex] = {
                startIndex: index.startIndex,
                endIndex: index.endIndex,
                is_booking_approved: element.is_booking_approved,
                is_over_night:isOverNight,
                colourStatus: element.status_id == 5? "unpaid" : element.is_trainer == 1? "trainer": "paid",
                ...element,
                bookings: element.attendance ? element.attendance : 0,
              };
            }
            if (element.status_id == 5) {
              facilityData.bookings[index.startIndex].colourStatus = "unpaid";
              if (element.is_booking_approved == 0) {
                facilityData.bookings[index.startIndex].colourStatus ="unapproved1";
              }
            } else if ( element.status_id == 1 || element.status_id == 4 || element.status_id == 21 ) {
              facilityData.bookings[index.startIndex].colourStatus = "paid";
              if (element.is_booking_approved == 0) {
                facilityData.bookings[index.startIndex].colourStatus = "unapproved";
              }
            }
          });

          facilityData.not_available = {};
          notAvailable.forEach((element) => {
            let index = 0;
            frTime = fcRentalTimes[element.facility_id];
            if(filter_date == element.date && element.booking_end_date && (filter_date != element.booking_end_date)){
              endTime = element.booking_end_date != element.date?frTime.fr_end_time:element.end_time;
              index = helper.parseOverNightTimeData(element.start_time,endTime);
            }else if(element.booking_end_date && filter_date == element.booking_end_date && (filter_date != element.date)){
              index = helper.parseOverNightTimeData(frTime.fr_start_time,element.end_time);
            }else{
              index = helper.parseTimeData(element);
            }
            if (!facilityData.not_available[index.startIndex]) {
              facilityData.not_available[index.startIndex] = {
                startIndex: index.startIndex,
                endIndex: index.endIndex,
                ...element,
              };
            } else {
              if (facilityData.not_available[index.startIndex].endIndex < index.endIndex) {
                facilityData.not_available[index.startIndex] = {
                  startIndex: index.startIndex,
                  endIndex: index.endIndex,
                  ...element,
                };
              }
            }
          });

          facilityData.cartItems = {};
          cartItems.forEach((element) => {
            let index = helper.parseTimeData(element);

            let timeDifference = index.endIndex - index.startIndex;

            if (timeDifference == 89) {
              timeDifference = 90;
            }else if (timeDifference % 10 === 9 || timeDifference % 10 === 4) {
              timeDifference += 1;
            }

            if (configuration.time_increment < timeDifference) {
              let startIndex = index.startIndex;
              let endIndex = index.endIndex;
              for (let i = configuration.time_increment; i <= timeDifference; i += configuration.time_increment) {

                endIndex = startIndex + configuration.time_increment;
                if (facilityData.cartItems[startIndex] == null) {
                  facilityData.cartItems[startIndex] = {
                    quantity: element.quantity ? element.quantity : 0,
                    startIndex: startIndex,
                    endIndex: endIndex,
                    ...element,
                  };
                } else {
                  facilityData.cartItems[startIndex].quantity += element.quantity;
                }
                startIndex = endIndex;
              }
            } else {
              if (facilityData.cartItems[index.startIndex] == null) {
                facilityData.cartItems[index.startIndex] = {
                  quantity: element.quantity ? element.quantity : 0,
                  startIndex: index.startIndex,
                  endIndex: index.endIndex,
                  ...element,
                };
              } else {
                facilityData.cartItems[index.startIndex].quantity += element.quantity;
              }
            }
          })

          facilityData.color_configuration = facility.color_configuration;
          return facilityData;
        });
        resolve(facilities);
      } else {
        reject(data);
      }
    });
  },

  arrangeScheduleBlocks(facilities, configuration) {
    return new Promise((resolve, reject) => {
      if (facilities.length > 0) {
        const totalDayMinutes = 1440;
        const divisions = totalDayMinutes / configuration.time_increment;
        let height = (60 / configuration.time_increment) * 1200;
        const cellHeight = parseInt(height / divisions);
        let scheduleData = [];
        facilities.forEach((facility) => {
          let data = [];
          let currentIndex = 0;
          let rental = null;
          for (let index = 0;index < totalDayMinutes; index += configuration.time_increment) {
            if (facility.rentals[index] != null) {
              rental = facility.rentals[index];
            }
            if (index >= currentIndex) {
              currentIndex = index + configuration.time_increment;
              let isPast = moment(configuration.date, "YYYY-MM-DD").startOf("day").add(currentIndex, "minutes").isSameOrBefore() && !configuration.backfill;
              let status = rental == null || !(rental && index >= rental.startIndex && index < rental.endIndex )? "not_available": isPast? "completed": "available";
              const element = {
                index: index,
                start_time: moment().startOf("day").add(index, "minutes").format("hh:mm a"),
                end_time: moment().startOf("day").add(currentIndex, "minutes").format("hh:mm a"),
                height: cellHeight,
                fixed: true,
                status: status,
                isPast: isPast,
                reserved_quantity:0
              };
              let element_start_time = moment().startOf("day").add(index, "minutes");
              let element_end_time = moment().startOf("day").add(currentIndex, "minutes");

              facility.color_configuration.forEach((col) => {
                let color_start = moment(col.start_time, "HH:mm:ss");
                let color_end = moment(col.end_time, "HH:mm:ss");

                if (
                  element_start_time.isSameOrAfter(color_start) &&
                  element_start_time.isSameOrBefore(color_end) &&
                  element_end_time.isSameOrAfter(color_start) &&
                  element_end_time.isSameOrBefore(color_end)
                ) {
                  element.bgColor = col.color_code;
                  element.bgColorName = col.name;
                }
              });
              if (facility.not_available[index] != null && rental != null) {
                currentIndex = facility.not_available[index].endIndex;
                let noOfCells = Math.ceil(
                  (facility.not_available[index].endIndex -
                    facility.not_available[index].startIndex) /
                    configuration.time_increment
                );
                element.status = "dependency";
                element.name = "DEPENDENT BOOKED";
                element.height = cellHeight * noOfCells + (noOfCells - 1);
              } else if (facility.maintenances[index] != null) {
                currentIndex = facility.maintenances[index].endIndex;
                let noOfCells =
                  (facility.maintenances[index].endIndex -
                    facility.maintenances[index].startIndex) /
                  configuration.time_increment;
                element.status = "maintenance";
                element.name = facility.maintenances[index].name;
                element.height = cellHeight * noOfCells + (noOfCells - 1);
              } else if (facility.events[index] != null) {
                currentIndex = facility.events[index].endIndex;
                let noOfCells =
                  (facility.events[index].endIndex -
                    facility.events[index].startIndex) /
                  configuration.time_increment;
                element.status = "event";
                element.name =
                  "EVENT BOOKED <br /> " + facility.events[index].event.name;

                element.height = cellHeight * noOfCells + (noOfCells - 1);
              } else if (facility.bookings[index] != null) {
                element.id = facility.bookings[index].id;
                currentIndex = facility.bookings[index].endIndex;
                let noOfCells = (facility.bookings[index].endIndex - facility.bookings[index].startIndex) / configuration.time_increment;
                element.status = facility.bookings[index].colourStatus;
                element.name = facility.bookings[index].name;
                element.products = facility.bookings[index].products;
                element.bookings = facility.bookings[index].bookings;
                if (facility.cartItems[index] != null){
                    element.reserved_quantity = facility.cartItems[index].quantity;
                }
                element.repeat_id = facility.bookings[index].facility_booking_repeat_id;
                element.is_split_payment = facility.bookings[index].is_split_payment;
                if(facility.bookings[index].is_split_payment==1){
                  element.bookings = facility.bookings[index].split_orders.length;
                  element.split_capacity = facility.bookings[index].group_customers.length;
                  if(element.bookings ==element.split_capacity)
                  element.status = "paid";
                }
                element.duplicate_id = facility.bookings[index].facility_booking_duplicate_id;
                element.order_id = facility.bookings[index].order_id;
                element.credit_owed = facility.bookings[index].credit_owed? facility.bookings[index].credit_owed: 0;
                element.participants = facility.bookings[index].participants;
                element.expected_height = cellHeight * noOfCells + (noOfCells - 1);
                noOfCells = Math.round(noOfCells);
                element.height = cellHeight * noOfCells + (noOfCells - 1);
                element.fill_height = element.height - element.expected_height;
                element.is_over_night= facility.bookings[index].is_over_night?facility.bookings[index].is_over_night:false;
              } else if (facility.workshops[index] != null) {
                currentIndex = facility.workshops[index].endIndex;
                let wStartIndex = facility.workshops[index].startIndex;
                element.name =
                  'ACADEMY BOOKED <br /> <span class="facility-name_header_inner" title="' +
                  facility.workshops[index].name.replace(/(<([^>]+)>)/gi, ",") +
                  '">' +
                  facility.workshops[index].name +
                  "</span>";
                for (
                  let wi = wStartIndex;
                  wi < currentIndex;
                  wi += configuration.time_increment
                ) {
                  if (wi != wStartIndex && facility.workshops[wi] != null) {
                    currentIndex = facility.workshops[wi].endIndex;
                    element.name += "<br /> " + facility.workshops[wi].name;
                  }
                }
                let noOfCells =
                  (currentIndex - wStartIndex) / configuration.time_increment;
                element.status = "event";
                element.height = cellHeight * noOfCells + (noOfCells - 1);
              }
              else if (facility.cartItems[index] != null) {
                currentIndex = facility.cartItems[index].endIndex;
                let noOfCells = (facility.cartItems[index].endIndex - facility.cartItems[index].startIndex) / configuration.time_increment;
                element.status = "cart";
                element.name = facility.cartItems[index].name;
                element.reserved_quantity = facility.cartItems[index].quantity;
                noOfCells = Math.round(noOfCells);
                element.height = cellHeight * noOfCells + (noOfCells - 1);
              }

              if (element.height < cellHeight) {
                element.expected_height = element.height;
                element.fill_height = cellHeight - element.expected_height;
                element.height = cellHeight;
              }
              data.push(element);
            }
          }
          scheduleData.push(data);
        });

        resolve(scheduleData);
      } else {
        reject(facilities);
      }
    });
  },

  parseTimeData(element) {
    let startTime = moment(element.start_time, "HH:mm:ss");
    let endTime = moment(element.end_time, "HH:mm:ss");
    let startIndex = startTime.hours() * 60 + startTime.minutes();
    let endIndex = endTime.hours() * 60 + endTime.minutes();
    return {
      startIndex: startIndex,
      endIndex: endIndex,
    };
  },

  parseTimeTillEndOfDay(element) {
    let startTime = moment(element.start_time, "HH:mm:ss");
    let endTime = moment(element.end_time, "HH:mm:ss").endOf("day");
    let startIndex = startTime.hours() * 60 + startTime.minutes();
    let endIndex = endTime.hours() * 60 + endTime.minutes();
    return {
      startIndex: startIndex,
      endIndex: endIndex,
    };
  },

  parseTimeFromStartOfDay(element) {
    let startTime = moment(element.start_time, "HH:mm:ss").startOf("day");
    let endTime = moment(element.end_time, "HH:mm:ss");
    let startIndex = startTime.hours() * 60 + startTime.minutes();
    let endIndex = endTime.hours() * 60 + endTime.minutes();
    return {
      startIndex: startIndex,
      endIndex: endIndex,
    };
  },

  parseTimeAllDay(element) {
    let startTime = moment(element.start_time, "HH:mm:ss").startOf("day");
    let endTime = moment(element.end_time, "HH:mm:ss").endOf("day");
    let startIndex = startTime.hours() * 60 + startTime.minutes();
    let endIndex = endTime.hours() * 60 + endTime.minutes();
    return {
      startIndex: startIndex,
      endIndex: endIndex,
    };
  },
  parseOverNightTimeData(start_time,end_time) {
    let startTime = moment(start_time, "HH:mm:ss");
    let endTime = moment(end_time, "HH:mm:ss");
    let startIndex = startTime.hours() * 60 + startTime.minutes();
    let endIndex = endTime.hours() * 60 + endTime.minutes();
    return {
      startIndex: startIndex,
      endIndex: endIndex,
    };
  },
  getFacilityStartTime(facilityRentals){
    const firstObject = facilityRentals[0];
    return firstObject?firstObject.start_time:'00:00';
  },
  getFacilityEndTime(facilityRentals){
    const lastObject = facilityRentals[facilityRentals.length - 1];
    return lastObject?lastObject.end_time:'11:59';
  },
  getFacilityRentalsStartEndTime(facilities) {
    let data = {};
    facilities.forEach((fac) => {
        if (fac.facility_rentals && fac.facility_rentals.length > 0) {
            let frStartTime = this.getFacilityStartTime(fac.facility_rentals);
            let frEndTime = this.getFacilityEndTime(fac.facility_rentals);
            data[fac.id] = {
                'fr_start_time': frStartTime,
                'fr_end_time': frEndTime
            };
        }else{
          data[fac.id] = {
            'fr_start_time': "00:00:00",
            'fr_end_time': "23:59:59"
          };
        }
    });
    return data;
}

};


const facilityBasedHelper = {
  parseScheduleData(data, configuration) {
    return new Promise((resolve, reject) => {
      if (data) {
        const filter_date = configuration.filter_date;
        // const fcRentalTimes = this.getFacilityRentalsStartEndTime(data);
        const facilities = Object.entries(data).map(([date,facility]) => {
        const fcRentalTimes = this.getFacilityRentalsStartEndTime(facility);
          // let facility = data;
          let facilityData = {
            id: facility.id,
            name: facility.name,
            currentDate:date,
            game_formations: facility.game_formations,
          };
          facilityData.rentals = {};
          let rentals = [];
          facility.facility_rentals.forEach((item) => {
            rentals.push(item);
          });
          rentals.forEach((element) => {
            let index = facilityBasedHelper.parseTimeData(element);
            facilityData.rentals[index.startIndex] = {
              startIndex: index.startIndex,
              endIndex: index.endIndex,
            };
          });
          let notAvailable = [];
          let events = [];
          let workshops = [];
          let maintenances = [];
          let cartItems = [];

          // let eventSchedules = [];
          facility.child_facilities.forEach((child) => {
            notAvailable.push(...child.bookings);
            // events.push(...child.events);
            events.push(...child.event_schedules);
            workshops.push(...child.workshop_program_schedules);
            workshops.push(...child.workshop_program_reschedules);
            maintenances.push(...child.maintenances);
          });
          facility.parent_facilities.forEach((parent) => {
            notAvailable.push(...parent.bookings);
            // events.push(...parent.events);
            events.push(...parent.event_schedules);
            workshops.push(...parent.workshop_program_schedules);
            workshops.push(...parent.workshop_program_reschedules);
            maintenances.push(...parent.maintenances);
          });
          // events.push(...facility.events);
          events.push(...facility.event_schedules);
          workshops.push(...facility.workshop_program_schedules);
          workshops.push(...facility.workshop_program_reschedules);
          maintenances.push(...facility.maintenances);
          cartItems.push(...facility.cart_items);

          facilityData.maintenances = {};
          maintenances.forEach((element) => {
            let index = facilityBasedHelper.parseTimeData(element);
            facilityData.maintenances[index.startIndex] = {
              startIndex: index.startIndex,
              endIndex: index.endIndex,
              ...element,
            };
          });

          facilityData.workshops = {};
          workshops.forEach((element) => {
            let index = facilityBasedHelper.parseTimeData(element);
            if (facilityData.workshops[index.startIndex]) {
              facilityData.workshops[index.startIndex].end_time =
                  element.end_time;
              facilityData.workshops[index.startIndex].endIndex =
                  index.endIndex;
              facilityData.workshops[index.startIndex].name +=
                  "<br /> " + element.name;
            } else {
              facilityData.workshops[index.startIndex] = {
                startIndex: index.startIndex,
                endIndex: index.endIndex,
                ...element,
              };
            }
          });

          facilityData.events = {};
          events.forEach((element) => {
            let index = facilityBasedHelper.parseTimeData(element);
            if (
                moment(configuration.date, "YYYY-MM-DD") <
                moment(element.end_date, "YYYY-MM-DD")
            ) {
              if (
                  moment(configuration.date, "YYYY-MM-DD") >
                  moment(element.start_date, "YYYY-MM-DD")
              ) {
                index = facilityBasedHelper.parseTimeAllDay(element);
              } else {
                index = facilityBasedHelper.parseTimeTillEndOfDay(element);
              }
            }
            if (
                moment(configuration.date, "YYYY-MM-DD") >
                moment(element.start_date, "YYYY-MM-DD")
            ) {
              if (
                  moment(configuration.date, "YYYY-MM-DD") <
                  moment(element.end_date, "YYYY-MM-DD")
              ) {
                index = facilityBasedHelper.parseTimeAllDay(element);
              } else {
                index = facilityBasedHelper.parseTimeFromStartOfDay(element);
              }
            }

            facilityData.events[index.startIndex] = {
              startIndex: index.startIndex,
              endIndex: index.endIndex,
              ...element,
            };
          });

          let frTime = fcRentalTimes[facility.id];
          let endTime = "11:59:59";
          facilityData.bookings = {};
          facility.bookings.forEach((element) => {
            let index = {};
            let isOverNight=false;
            if (facility.per_capacity === 1) {
              index = facilityBasedHelper.parseTimeData(element);
              let timeDifference = index.endIndex - index.startIndex;
              if (timeDifference == 89) {
                timeDifference = 90;
              }
              /** calculate parent total */
              let parentOrderTotal = 0;
              if (element.parent_orders) {
                element.parent_orders.map((item) => {
                  parentOrderTotal += item.total;
                });
              }
              if (configuration.time_increment < timeDifference) {
                let startIndex = index.startIndex;
                let endIndex = index.endIndex;
                for (let i = configuration.time_increment;i <= timeDifference; i += configuration.time_increment) {
                  endIndex = startIndex + configuration.time_increment;
                  if (facilityData.bookings[startIndex] == null) {
                    facilityData.bookings[startIndex] = {
                      bookings: element.attendance ? element.attendance : 0,
                      startIndex: startIndex,
                      endIndex: endIndex,
                      participants: [],
                      duplicate_id: element.facility_booking_duplicate_id,
                      is_booking_approved: element.is_booking_approved,
                      is_over_night:isOverNight,
                      colourStatus:
                          element.status_id == 5
                              ? "unpaid"
                              : element.is_trainer == 1
                                  ? "trainer"
                                  : "paid",
                      ...element,
                    };
                  } else {
                    facilityData.bookings[startIndex].bookings += element.attendance;
                    facilityData.bookings[startIndex].colourStatus = element.status_id == 5? "unpaid" : facilityData.bookings[startIndex].colourStatus;
                  }
                  if (element.group_customers) {
                    let isIPaid = false;
                    if (element.attendance == element.group_customers.length) {
                      element.group_customers.map((item) => {
                        item.facility_id = element.facility_id;
                        item.status_id = element.status_id;
                        item.order_total = element.total + parentOrderTotal;
                        item.is_trainer = element.is_trainer == 1 ? 1 : 0;
                        if ( item.customer_id == element.customer_id && !isIPaid) {
                          isIPaid = true;
                          item.i_paid = 1;
                        } else {
                          item.i_paid = 0;
                        }
                        facilityData.bookings[startIndex].participants.push(item);
                      });
                    } else if ( element.attendance > element.group_customers.length) {
                      let totalCustomersCount = element.group_customers.length;
                      let remainingCustomerCount = element.attendance - totalCustomersCount;
                      element.group_customers.map((item) => {
                        item.facility_id = element.facility_id;
                        item.status_id = element.status_id;
                        item.order_total = element.total + parentOrderTotal;
                        item.is_trainer = element.is_trainer == 1 ? 1 : 0;
                        if (item.customer_id == element.customer_id && !isIPaid) {
                          isIPaid = true;
                          item.i_paid = 1;
                        } else {
                          item.i_paid = 0;
                        }
                        facilityData.bookings[startIndex].participants.push(item);
                      });

                      for (let step = 0; step < remainingCustomerCount; step++) {
                        let copy = { ...element.group_customers[0],};
                        copy.facility_id = element.facility_id;
                        copy.status_id = element.status_id;
                        copy.order_total = element.total + parentOrderTotal;
                        copy.is_trainer = element.is_trainer == 1 ? 1 : 0;
                        copy.products = element.products;
                        copy.fb_duplicate_id = element.facility_booking_duplicate_id;
                        copy.i_paid = 0;
                        facilityData.bookings[startIndex].participants.push(copy);
                      }
                    } else {
                      element.group_customers.map((item) => {
                        item.facility_id = element.facility_id;
                        item.status_id = element.status_id;
                        item.order_total = element.total + parentOrderTotal;
                        item.is_trainer = element.is_trainer == 1 ? 1 : 0;
                        item.products = element.products;
                        item.fb_duplicate_id = element.facility_booking_duplicate_id;
                        if (item.customer_id == element.customer_id && !isIPaid) {
                          isIPaid = true;
                          item.i_paid = 1;
                        } else {
                          item.i_paid = 0;
                        }
                        facilityData.bookings[startIndex].participants.push(item);
                      });
                    }
                  }
                  startIndex = endIndex;
                }
              } else {
                if (facilityData.bookings[index.startIndex] == null) {
                  facilityData.bookings[index.startIndex] = {
                    bookings: element.attendance ? element.attendance : 0,
                    startIndex: index.startIndex,
                    endIndex: index.endIndex,
                    participants: [],
                    is_booking_approved: element.is_booking_approved,
                    colourStatus: element.status_id == 5? "unpaid": element.is_trainer == 1? "trainer": "paid",
                    ...element,
                  };
                } else {
                  facilityData.bookings[index.startIndex].bookings += element.attendance;
                }
                if (element.group_customers) {
                  let isIPaid = false;
                  if (element.attendance == element.group_customers.length) {
                    element.group_customers.map((item) => {
                      item.facility_id = element.facility_id;
                      item.status_id = element.status_id;
                      item.order_total = element.total + parentOrderTotal;
                      item.is_trainer = element.is_trainer == 1 ? 1 : 0;
                      item.products = element.products;
                      item.fb_duplicate_id = element.facility_booking_duplicate_id;
                      if (item.customer_id == element.customer_id && !isIPaid) {
                        isIPaid = true;
                        item.i_paid = 1;
                      } else {
                        item.i_paid = 0;
                      }
                      facilityData.bookings[index.startIndex].participants.push(item);
                    });
                  } else if (element.attendance > element.group_customers.length) {
                    let totalCustomersCount = element.group_customers.length;
                    let remainingCustomerCount = element.attendance - totalCustomersCount;
                    element.group_customers.map((item) => {
                      item.facility_id = element.facility_id;
                      item.status_id = element.status_id;
                      item.order_total = element.total + parentOrderTotal;
                      item.is_trainer = element.is_trainer == 1 ? 1 : 0;
                      item.products = element.products;
                      item.fb_duplicate_id = element.facility_booking_duplicate_id;
                      if (item.customer_id == element.customer_id && !isIPaid) {
                        isIPaid = true;
                        item.i_paid = 1;
                      } else {
                        item.i_paid = 0;
                      }
                      facilityData.bookings[index.startIndex].participants.push(item);
                    });

                    for (let step = 0; step < remainingCustomerCount; step++) {
                      let copy = { ...element.group_customers[0],};
                      copy.facility_id = element.facility_id;
                      copy.status_id = element.status_id;
                      copy.order_total = element.total + parentOrderTotal;
                      copy.is_trainer = element.is_trainer == 1 ? 1 : 0;
                      copy.is_extra = 1;
                      copy.i_paid = 0;
                      copy.products = element.products;
                      copy.fb_duplicate_id =
                          element.facility_booking_duplicate_id;
                      facilityData.bookings[index.startIndex].participants.push(
                          copy
                      );
                    }
                  } else {
                    element.group_customers.map((item) => {
                      item.facility_id = element.facility_id;
                      item.status_id = element.status_id;
                      item.order_total = element.total + parentOrderTotal;
                      item.is_trainer = element.is_trainer == 1 ? 1 : 0;
                      if (item.customer_id == element.customer_id && !isIPaid) {
                        isIPaid = true;
                        item.i_paid = 1;
                      } else {
                        item.i_paid = 0;
                      }
                      item.products = element.products;
                      item.fb_duplicate_id = element.facility_booking_duplicate_id;
                      facilityData.bookings[index.startIndex].participants.push(item);
                    });
                  }
                }
              }
            } else {
              if(date == element.date && element.booking_end_date && (date != element.booking_end_date)){
                endTime = element.booking_end_date != element.date?frTime.fr_end_time:element.end_time;
                index = facilityBasedHelper.parseOverNightTimeData(element.start_time,endTime);
              }else if(element.booking_end_date && date == element.booking_end_date && (date != element.date)){
                index = facilityBasedHelper.parseOverNightTimeData(frTime.fr_start_time,element.end_time);
                isOverNight = true;
              }else{
                index = facilityBasedHelper.parseTimeData(element);
              }
              facilityData.bookings[index.startIndex] = {
                startIndex: index.startIndex,
                endIndex: index.endIndex,
                is_booking_approved: element.is_booking_approved,
                is_over_night:isOverNight,
                colourStatus: element.status_id == 5? "unpaid" : element.is_trainer == 1? "trainer": "paid",
                ...element,
                bookings: element.attendance ? element.attendance : 0,
              };
            }
            if (element.status_id == 5) {
              facilityData.bookings[index.startIndex].colourStatus = "unpaid";
              if (element.is_booking_approved == 0) {
                facilityData.bookings[index.startIndex].colourStatus ="unapproved1";
              }
            } else if ( element.status_id == 1 || element.status_id == 4 || element.status_id == 21 ) {
              facilityData.bookings[index.startIndex].colourStatus = "paid";
              if (element.is_booking_approved == 0) {
                facilityData.bookings[index.startIndex].colourStatus = "unapproved";
              }
            }
          });

          facilityData.not_available = {};
          notAvailable.forEach((element) => {
            let index = 0;
            frTime = fcRentalTimes[element.facility_id];
            if(filter_date == element.date && element.booking_end_date && (filter_date != element.booking_end_date)){
              endTime = element.booking_end_date != element.date?frTime.fr_end_time:element.end_time;
              index = facilityBasedHelper.parseOverNightTimeData(element.start_time,endTime);
            }else if(element.booking_end_date && filter_date == element.booking_end_date && (filter_date != element.date)){
              index = facilityBasedHelper.parseOverNightTimeData(frTime.fr_start_time,element.end_time);
            }else{
              index = facilityBasedHelper.parseTimeData(element);
            }
            if (!facilityData.not_available[index.startIndex]) {
              facilityData.not_available[index.startIndex] = {
                startIndex: index.startIndex,
                endIndex: index.endIndex,
                ...element,
              };
            } else {
              if (facilityData.not_available[index.startIndex].endIndex < index.endIndex) {
                facilityData.not_available[index.startIndex] = {
                  startIndex: index.startIndex,
                  endIndex: index.endIndex,
                  ...element,
                };
              }
            }
          });

          facilityData.cartItems = {};
          cartItems.forEach((element) => {
            let index = helper.parseTimeData(element);

            let timeDifference = index.endIndex - index.startIndex;

            if (timeDifference == 89) {
              timeDifference = 90;
            }else if (timeDifference % 10 === 9 || timeDifference % 10 === 4) {
              timeDifference += 1;
            }

            if (configuration.time_increment < timeDifference) {
              let startIndex = index.startIndex;
              let endIndex = index.endIndex;
              for (let i = configuration.time_increment; i <= timeDifference; i += configuration.time_increment) {

                endIndex = startIndex + configuration.time_increment;
                if (facilityData.cartItems[startIndex] == null) {
                  facilityData.cartItems[startIndex] = {
                    quantity: element.quantity ? element.quantity : 0,
                    startIndex: startIndex,
                    endIndex: endIndex,
                    ...element,
                  };
                } else {
                  facilityData.cartItems[startIndex].quantity += element.quantity;
                }
                startIndex = endIndex;
              }
            } else {
              if (facilityData.cartItems[index.startIndex] == null) {
                facilityData.cartItems[index.startIndex] = {
                  quantity: element.quantity ? element.quantity : 0,
                  startIndex: index.startIndex,
                  endIndex: index.endIndex,
                  ...element,
                };
              } else {
                facilityData.cartItems[index.startIndex].quantity += element.quantity;
              }
            }
          })

          facilityData.color_configuration = facility.color_configuration;
          return facilityData;
        });
        // const facilities = facilityData;
        resolve(facilities);
      } else {
        reject(data);
      }
    });
  },

  arrangeScheduleBlocks(facilities, configuration) {
    return new Promise((resolve, reject) => {
      if (facilities.length > 0) {
        const totalDayMinutes = 1440;
        const divisions = totalDayMinutes / configuration.time_increment;
        let height = (60 / configuration.time_increment) * 1200;
        const cellHeight = parseInt(height / divisions);
        let scheduleData = [];
        facilities.forEach((facility) => {
          let data = [];
          let currentIndex = 0;
          let rental = null;
          for (let index = 0;index < totalDayMinutes; index += configuration.time_increment) {
            if (facility.rentals[index] != null) {
              rental = facility.rentals[index];
            }
            if (index >= currentIndex) {
              currentIndex = index + configuration.time_increment;
              let checkDate = facility.currentDate ? facility.currentDate : configuration.date;
              let isPast = moment(checkDate, "YYYY-MM-DD").startOf("day").add(currentIndex, "minutes").isSameOrBefore() && !configuration.backfill;
              let status = rental == null || !(rental && index >= rental.startIndex && index < rental.endIndex )? "not_available": isPast? "completed": "available";
              const element = {
                index: index,
                start_time: moment().startOf("day").add(index, "minutes").format("hh:mm a"),
                end_time: moment().startOf("day").add(currentIndex, "minutes").format("hh:mm a"),
                height: cellHeight,
                fixed: true,
                status: status,
                isPast: isPast,
              };
              let element_start_time = moment().startOf("day").add(index, "minutes");
              let element_end_time = moment().startOf("day").add(currentIndex, "minutes");

              facility.color_configuration.forEach((col) => {
                let color_start = moment(col.start_time, "HH:mm:ss");
                let color_end = moment(col.end_time, "HH:mm:ss");

                if (
                    element_start_time.isSameOrAfter(color_start) &&
                    element_start_time.isSameOrBefore(color_end) &&
                    element_end_time.isSameOrAfter(color_start) &&
                    element_end_time.isSameOrBefore(color_end)
                ) {
                  element.bgColor = col.color_code;
                  element.bgColorName = col.name;
                }
              });
              if (facility.not_available[index] != null && rental != null) {
                currentIndex = facility.not_available[index].endIndex;
                let noOfCells = Math.ceil(
                    (facility.not_available[index].endIndex -
                        facility.not_available[index].startIndex) /
                    configuration.time_increment
                );
                element.status = "dependency";
                element.name = "DEPENDENT BOOKED";
                element.height = cellHeight * noOfCells + (noOfCells - 1);
              } else if (facility.maintenances[index] != null) {
                currentIndex = facility.maintenances[index].endIndex;
                let noOfCells =
                    (facility.maintenances[index].endIndex -
                        facility.maintenances[index].startIndex) /
                    configuration.time_increment;
                element.status = "maintenance";
                element.name = facility.maintenances[index].name;
                element.height = cellHeight * noOfCells + (noOfCells - 1);
              } else if (facility.events[index] != null) {
                currentIndex = facility.events[index].endIndex;
                let noOfCells =
                    (facility.events[index].endIndex -
                        facility.events[index].startIndex) /
                    configuration.time_increment;
                element.status = "event";
                element.name =
                    "EVENT BOOKED <br /> " + facility.events[index].event.name;

                element.height = cellHeight * noOfCells + (noOfCells - 1);
              } else if (facility.bookings[index] != null) {
                element.id = facility.bookings[index].id;
                currentIndex = facility.bookings[index].endIndex;
                let noOfCells = (facility.bookings[index].endIndex - facility.bookings[index].startIndex) / configuration.time_increment;
                element.status = facility.bookings[index].colourStatus;
                element.name = facility.bookings[index].name;
                element.products = facility.bookings[index].products;
                element.bookings = facility.bookings[index].bookings;
                if (facility.cartItems[index] != null){
                  element.reserved_quantity = facility.cartItems[index].quantity;
                }
                element.repeat_id = facility.bookings[index].facility_booking_repeat_id;
                element.duplicate_id = facility.bookings[index].facility_booking_duplicate_id;
                element.order_id = facility.bookings[index].order_id;
                element.credit_owed = facility.bookings[index].credit_owed? facility.bookings[index].credit_owed: 0;
                element.participants = facility.bookings[index].participants;
                element.expected_height = cellHeight * noOfCells + (noOfCells - 1);
                noOfCells = Math.round(noOfCells);
                element.height = cellHeight * noOfCells + (noOfCells - 1);
                element.fill_height = element.height - element.expected_height;
                element.is_over_night= facility.bookings[index].is_over_night?facility.bookings[index].is_over_night:false;
                element.is_split_payment = facility.bookings[index].is_split_payment;
                if(facility.bookings[index].is_split_payment==1){
                  element.bookings = facility.bookings[index].split_orders.length;
                  element.split_capacity = facility.bookings[index].group_customers.length;
                  if(element.bookings ==element.split_capacity)
                  element.status = "paid";
                }
              } else if (facility.workshops[index] != null) {
                currentIndex = facility.workshops[index].endIndex;
                let wStartIndex = facility.workshops[index].startIndex;
                element.name =
                    'ACADEMY BOOKED <br /> <span class="facility-name_header_inner" title="' +
                    facility.workshops[index].name.replace(/(<([^>]+)>)/gi, ",") +
                    '">' +
                    facility.workshops[index].name +
                    "</span>";
                for (
                    let wi = wStartIndex;
                    wi < currentIndex;
                    wi += configuration.time_increment
                ) {
                  if (wi != wStartIndex && facility.workshops[wi] != null) {
                    currentIndex = facility.workshops[wi].endIndex;
                    element.name += "<br /> " + facility.workshops[wi].name;
                  }
                }
                let noOfCells =
                    (currentIndex - wStartIndex) / configuration.time_increment;
                element.status = "event";
                element.height = cellHeight * noOfCells + (noOfCells - 1);
              } else if (facility.cartItems[index] != null) {
                currentIndex = facility.cartItems[index].endIndex;
                let noOfCells = (facility.cartItems[index].endIndex - facility.cartItems[index].startIndex) / configuration.time_increment;
                element.status = "cart";
                element.name = facility.cartItems[index].name;
                element.reserved_quantity = facility.cartItems[index].quantity;
                noOfCells = Math.round(noOfCells);
                element.height = cellHeight * noOfCells + (noOfCells - 1);
              }

              if (element.height < cellHeight) {
                element.expected_height = element.height;
                element.fill_height = cellHeight - element.expected_height;
                element.height = cellHeight;
              }
              element.current_date = facility.currentDate;
              data.push(element);
            }
          }
          scheduleData[facility.currentDate] = data;
        });

        resolve(scheduleData);
      } else {
        reject(facilities);
      }
    });
  },

  parseTimeData(element) {
    let startTime = moment(element.start_time, "HH:mm:ss");
    let endTime = moment(element.end_time, "HH:mm:ss");
    let startIndex = startTime.hours() * 60 + startTime.minutes();
    let endIndex = endTime.hours() * 60 + endTime.minutes();
    return {
      startIndex: startIndex,
      endIndex: endIndex,
    };
  },

  parseTimeTillEndOfDay(element) {
    let startTime = moment(element.start_time, "HH:mm:ss");
    let endTime = moment(element.end_time, "HH:mm:ss").endOf("day");
    let startIndex = startTime.hours() * 60 + startTime.minutes();
    let endIndex = endTime.hours() * 60 + endTime.minutes();
    return {
      startIndex: startIndex,
      endIndex: endIndex,
    };
  },

  parseTimeFromStartOfDay(element) {
    let startTime = moment(element.start_time, "HH:mm:ss").startOf("day");
    let endTime = moment(element.end_time, "HH:mm:ss");
    let startIndex = startTime.hours() * 60 + startTime.minutes();
    let endIndex = endTime.hours() * 60 + endTime.minutes();
    return {
      startIndex: startIndex,
      endIndex: endIndex,
    };
  },

  parseTimeAllDay(element) {
    let startTime = moment(element.start_time, "HH:mm:ss").startOf("day");
    let endTime = moment(element.end_time, "HH:mm:ss").endOf("day");
    let startIndex = startTime.hours() * 60 + startTime.minutes();
    let endIndex = endTime.hours() * 60 + endTime.minutes();
    return {
      startIndex: startIndex,
      endIndex: endIndex,
    };
  },
  parseOverNightTimeData(start_time,end_time) {
    let startTime = moment(start_time, "HH:mm:ss");
    let endTime = moment(end_time, "HH:mm:ss");
    let startIndex = startTime.hours() * 60 + startTime.minutes();
    let endIndex = endTime.hours() * 60 + endTime.minutes();
    return {
      startIndex: startIndex,
      endIndex: endIndex,
    };
  },
  getFacilityStartTime(facilityRentals){
    const firstObject = facilityRentals[0];
    return firstObject?firstObject.start_time:'00:00';
  },
  getFacilityEndTime(facilityRentals){
    const lastObject = facilityRentals[facilityRentals.length - 1];
    return lastObject?lastObject.end_time:'11:59';
  },
  getFacilityRentalsStartEndTime(facilities) {
    let data = {};
      if (facilities.facility_rentals && facilities.facility_rentals.length > 0) {
        let frStartTime = this.getFacilityStartTime(facilities.facility_rentals);
        let frEndTime = this.getFacilityEndTime(facilities.facility_rentals);
        data[facilities.id] = {
          'fr_start_time': frStartTime,
          'fr_end_time': frEndTime
        };
      }else{
        data[facilities.id] = {
          'fr_start_time': "00:00:00",
          'fr_end_time': "23:59:59"
        };
      }
    return data;
  }

};

const mutations = {
  resetFacilitySchedule(state) {
    Object.assign(state, getFacilityScheduleState());
  },
  setFacilitySchedule: (state, data) => {
    state.facilitySchedule = data;
  },
  HIGHLIGHT: (state, data) => {
    state.hover = data;
  },
  REMOVE_HIGHLIGHT: (state, data) => {
    state.hover = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
